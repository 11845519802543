import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CardContent, Radio } from "@mui/material";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Typography, Checkbox } from "@mui/material";
import TextInput from "../../../../../../components/Utils/TextInput";
import { useContext } from "react";
import {
	ClassesContext,
	TeamTeachingContext,
	StartDateContext,
	EndDateContext,
} from "../../../../../../context/RequisitionContext";

export default function CombineDialog(props) {
	const [validChoices, setValidChoices] = useState(false);
	const [rolePicked, setRolePicked] = useState(null);
	const [chosenCTitle, setChosenCTitle] = useState(null);
	const [suggestedCtitle, setSuggestedCTitle] = useState(null);
	const [commonInstructors, setCommonInstructors] = useState([]);
	const [newClass, setNewClass] = useState(null);
	const startDate = useContext(StartDateContext);
	const endDate = useContext(EndDateContext);
	const { chosenClasses, setChosenClasses } = useContext(ClassesContext);
	const teamTeachingAgree = useContext(TeamTeachingContext);

	const [lecCoursesCheck, setLecCoursesCheck] = useState([]);
	const [otherCoursesCheck, setOtherCoursesCheck] = useState([]);

	useEffect(() => {
		if (props.list && props.list.length > 1) {
			const commonNames = props.list[0].roles.filter((role) => {
				return props.list.every((instructor) => {
					return instructor.roles.some((role2) => {
						return role.name === role2.name;
					});
				});
			});
			setCommonInstructors(commonNames);
		}
	}, [props.list]);

	useEffect(() => {
		if (rolePicked && chosenCTitle) {
			setValidChoices(true);
		} else {
			setValidChoices(false);
		}
	}, [rolePicked, chosenCTitle]);

	useEffect(() => {
		if (props.list && props.list.length > 1) {
			//console.log("props.list", props.list);
			let subject_id = props.list[0].subject_id;
			let cnumber = props.list[0].cnumber;
			let ctypeForCtitle = props.list[0].ctype;
			let section = props.list[0].section;
			let ctype = "";
			let comb_classes = [];

			const sortedClasses = [...props.list].sort((a, b) => {
				return a.cnumber - b.cnumber;
			});

			if (sortedClasses.some((c) => c.ctype === "LEC")) {
				ctype = "LEC";
			} else if (
				sortedClasses.some((c) => c.ctype === "LAB") &&
				!sortedClasses.some((c) => c.ctype === "LEC")
			) {
				ctype = "LAB";
			} else {
				ctype = sortedClasses[0].ctype;
			}

			const sameSubjectId = sortedClasses.every(
				(c) => c.subject_id === subject_id
			);
			const sameCnumber = sortedClasses.every((c) => c.cnumber === cnumber);
			const sameCtype = sortedClasses.every((c) => c.ctype === ctype);
			const sameSection = sortedClasses.every((c) => c.section === section);
			if (!sameSubjectId) {
				const subject_idArray = sortedClasses.map((c) => c.subject_id);
				subject_idArray.filter((subject_id, index) => {
					return subject_idArray.indexOf(subject_id) === index;
				});
				subject_id = subject_idArray.join("/");
			}
			if (!sameCnumber) {
				const cnumberArray = sortedClasses.map((c) => c.cnumber);
				cnumberArray.filter((cnumber, index) => {
					return cnumberArray.indexOf(cnumber) === index;
				});
				cnumber = cnumberArray.join("/");
			}
			if (!sameCtype) {
				const ctypeArray = sortedClasses.map((c) => c.ctype);
				ctypeArray.filter((ctype, index) => {
					return ctypeArray.indexOf(ctype) === index;
				});
				ctypeForCtitle = ctypeArray.join("/");
			}
			if (!sameSection) {
				const sectionArray = sortedClasses.map((c) => c.section);
				sectionArray.filter((section, index) => {
					return sectionArray.indexOf(section) === index;
				});
				section = sectionArray.join("/");
			}

			let lecCounter = 0;
			let lecCourses = [];
			let otherCourses = [];
			sortedClasses.forEach((c) => {
				if (c.ctype === "LEC") {
					lecCounter += 1;
					lecCourses.push(c);
				} else {
					otherCourses.push(c);
				}
			});

			setLecCoursesCheck(lecCourses);
			setOtherCoursesCheck(otherCourses);

			if (lecCounter === 1) {
				const lecCnumber = lecCourses[0].cnumber;
				const lecSubjectId = lecCourses[0].subject_id;
				otherCourses.forEach((c) => {
					if (
						c.cnumber === lecCnumber &&
						c.subject_id === lecSubjectId &&
						(c.ctype === "LAB" || c.ctype === "SEM")
					) {
						props.setUnwantedCombination(true);
					} else {
						props.setUnwantedCombination(false);
					}
				});
			}

			let newCtitle = "";
			newCtitle = `${subject_id} ${cnumber} ${ctypeForCtitle} ${section}`;
			setSuggestedCTitle(newCtitle);
			let newCsize = 0;

			sortedClasses.forEach((c) => {
				newCsize += c.csize;
			});

			sortedClasses.forEach((c, index) => {
				if (index !== 0) {
					comb_classes.push(c.classid);
					if (c.combine && c.combine.length > 0) comb_classes.push(c.combine);
				}
			});

			if (sortedClasses[0].combine && sortedClasses[0].combine.length > 0) {
				comb_classes.push(sortedClasses[0].combine);
			}

			let empid = null;
			if (rolePicked) {
				if (!teamTeachingAgree) {
					empid = sortedClasses[0].roles.find((instructor) => {
						return instructor.name === rolePicked;
					}).empid;
				} else {
					empid = [];
					rolePicked.forEach((role) => {
						empid.push(
							sortedClasses[0].roles.find((instructor) => {
								return instructor.name === role;
							}).empid
						);
					});
				}
			}

			let class_role = null;
			if (rolePicked) {
				if (!teamTeachingAgree) {
					class_role = sortedClasses[0].roles.find((instructor) => {
						return instructor.name === rolePicked;
					}).class_role;
				} else {
					class_role = [];
					rolePicked.forEach((role) => {
						class_role.push(
							sortedClasses[0].roles.find((instructor) => {
								return instructor.name === role;
							}).class_role
						);
					});
				}
			}

			let roles = [];
			if (rolePicked) {
				if (!teamTeachingAgree) {
					roles.push(
						sortedClasses[0].roles.find((instructor) => {
							return instructor.name === rolePicked;
						})
					);
				} else {
					rolePicked.forEach((role) => {
						roles.push(
							sortedClasses[0].roles.find((instructor) => {
								return instructor.name === role;
							})
						);
					});
				}
			}

			let newId = 0;
			let newIdInModifyReq = 0;

			if (props.inModifyReq) {
				if (sortedClasses.length > 1) {
					sortedClasses.forEach((c) => {
						newIdInModifyReq += c.cls_uqid;
					});
				}
			} else {
				if (sortedClasses.length > 1) {
					sortedClasses.forEach((c) => {
						newId += c.classid;
					});
				}
			}

			const newClass = {
				subject_id: subject_id,
				cnumber: cnumber,
				ctype: ctype,
				section: section,
				acyear: sortedClasses[0].acyear,
				c_edate: sortedClasses[0].c_edate, //how handle for combine classes? If same, or within 4 days, then keep, else dont combine at all
				c_sdate: sortedClasses[0].c_sdate,
				catalog: sortedClasses[0].catalog,
				classid: sortedClasses[0].classid,
				description: sortedClasses[0].description,
				c_location: sortedClasses[0].c_location,

				rank: class_role,
				class_role: class_role,
				combined: true,
				comb_classes: comb_classes, //I think
				cls_uqid: newIdInModifyReq,
				csize: newCsize,
				ctitle: chosenCTitle,
				empid: empid,
				faculty: sortedClasses[0].faculty,
				dept: sortedClasses[0].dept,
				examdate: sortedClasses[0].examdate, //how handle for combine classes? If conflict with survey window, do not allow combine at all. Lock in dates before classes are picked
				id: newId,
				name: rolePicked,
				original: sortedClasses[0].original,
				program: sortedClasses[0].program,
				roles: roles,
				schedule: sortedClasses[0].schedule,
				survey_end: endDate != null ? endDate : sortedClasses[0].survey_end,
				survey_start:
					startDate != null ? startDate : sortedClasses[0].survey_start,
				teamsize: sortedClasses[0].teamsize,
				term: sortedClasses[0].term,
			};

			if (!teamTeachingAgree) {
				setNewClass(newClass);
			} else {
				let newRoles = [];
				if (props.inModifyReq) {
					if (newClass.roles.length > 1) {
						newClass.roles.forEach((role, index) => {
							if (index === 0) {
								const updatedNewClass = { ...newClass };
								updatedNewClass.empid = role.empid;
								updatedNewClass.ccid = role.ccid;
								updatedNewClass.class_role = role.class_role;
								updatedNewClass.rank = role.class_role;
								updatedNewClass.name = role.name;
								updatedNewClass.roles = [role];
								updatedNewClass.cls_uqid = newClass.cls_uqid + role.empid;
								newRoles.push(updatedNewClass);
							} else {
								const updatedNewClass = { ...newClass };
								updatedNewClass.empid = role.empid;
								updatedNewClass.class_role = role.class_role;
								updatedNewClass.ccid = role.ccid;
								updatedNewClass.rank = role.class_role;
								updatedNewClass.name = role.name;
								updatedNewClass.roles = [role];
								updatedNewClass.cls_uqid = newClass.cls_uqid + role.empid;
								updatedNewClass.csize = 0;
								newRoles.push(updatedNewClass);
							}
						});
					}
				} else {
					if (newClass.roles.length > 1) {
						newClass.roles.forEach((role, index) => {
							if (index === 0) {
								const updatedNewClass = { ...newClass };
								updatedNewClass.empid = role.empid;
								updatedNewClass.class_role = role.class_role;
								updatedNewClass.ccid = role.ccid;
								updatedNewClass.rank = role.class_role;
								updatedNewClass.name = role.name;
								updatedNewClass.roles = [role];
								updatedNewClass.id = newClass.id + (index + 1) + 100000;
								newRoles.push(updatedNewClass);
							} else {
								const updatedNewClass = { ...newClass };
								updatedNewClass.empid = role.empid;
								updatedNewClass.class_role = role.class_role;
								updatedNewClass.ccid = role.ccid;
								updatedNewClass.rank = role.class_role;
								updatedNewClass.name = role.name;
								updatedNewClass.roles = [role];
								updatedNewClass.id = newClass.id + (index + 1) + 100000;
								updatedNewClass.csize = 0;
								newRoles.push(updatedNewClass);
							}
						});
					}
				}
				setNewClass(newRoles);
			}
		} else {
			props.setUnwantedCombination(false);
		}
	}, [
		props.list,
		rolePicked,
		chosenCTitle,
		teamTeachingAgree,
		props.inModifyReq,
		startDate,
		endDate,
	]);

	const handleClose = (event, reason) => {
		if (reason && reason === "backdropClick") return;
		props.setOpen(false);
		setRolePicked(null);
		setChosenCTitle(null);
		setCommonInstructors((prev) => {
			const updatedInstructors = [...prev];
			updatedInstructors.forEach((instructor) => {
				instructor.checked = false;
			});
			return updatedInstructors;
		});
	};

	const onChange = (event) => {
		if (!teamTeachingAgree) {
			setRolePicked(event.target.value);
		} else {
			setCommonInstructors((prev) => {
				const updatedInstructors = [...prev];
				const instructorIndex = updatedInstructors.findIndex(
					(instructor) => instructor.name === event.target.value
				);
				updatedInstructors[instructorIndex].checked = !updatedInstructors[
					instructorIndex
				].checked
					? true
					: false;
				return updatedInstructors;
			});
			if (rolePicked) {
				setRolePicked((prev) => {
					const updatedRoles = [...prev];
					if (updatedRoles.includes(event.target.value)) {
						const index = updatedRoles.indexOf(event.target.value);
						updatedRoles.splice(index, 1);
						return updatedRoles;
					}

					updatedRoles.push(event.target.value);
					return updatedRoles;
				});
			} else {
				setRolePicked([event.target.value]);
			}
		}
	};

	const handleSuggestion = () => {
		let suggestedTest = suggestedCtitle;
		setChosenCTitle(suggestedTest);
	};

	const textOnChange = (event) => {
		setChosenCTitle(event.target.value);
	};

	const handleSubmit = () => {
		const updatedClasses = [...chosenClasses];
		if (!teamTeachingAgree) {
			const classWithCCID = { ...newClass, ccid: newClass.roles[0].ccid };
			updatedClasses.push(classWithCCID);
		} else {
			if (newClass.length > 0) {
				newClass.forEach((role) => {
					const classWithCCID = { ...role, ccid: role.roles[0].ccid };
					// console.log("classWithCCID", classWithCCID);
					updatedClasses.push(classWithCCID);
				});
			}
		}
		setChosenClasses(updatedClasses);
		props.setOpen(false);
		setRolePicked(null);
		setChosenCTitle(null);
		setCommonInstructors((prev) => {
			const updatedInstructors = [...prev];
			if (updatedInstructors.length > 0) {
				updatedInstructors.forEach((instructor) => {
					instructor.checked = false;
				});
			}

			return updatedInstructors;
		});
		props.setList([]);
	};

	return (
		<div>
			{teamTeachingAgree && props.list.length > 0 && (
				<Dialog
					open={props.open}
					onClose={handleClose}
					/* PaperProps={{ variant: "outlined" }} */
					maxWidth="xl"
				>
					<DialogTitle textAlign={"center"}>{"Choose Instructors"}</DialogTitle>

					<DialogContent
						sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
					>
						<DialogContentText sx={{ marginBottom: "20px" }}>
							These instructors are common across all selected courses.
						</DialogContentText>
						<DialogContentText sx={{ marginBottom: "20px" }}>
							WARNING: It seems like the selected classes might not be suitable
							for combining. Please do not combine LAB or SEM components with a
							LEC component that has the same students enrolled.
						</DialogContentText>
						{commonInstructors.length > 0 ? (
							<FormControl component="fieldset">
								<FormGroup>
									{commonInstructors.map((role) => {
										return (
											<FormControlLabel
												control={
													<Checkbox
														key={role.empid}
														disabled={role.disabled}
														checked={role.checked}
														onChange={onChange}
														name={role.name}
														value={role.name || ""}
													/>
												}
												label={role.name + " (" + role.role_description + ")"}
											/>
										);
									})}
								</FormGroup>
							</FormControl>
						) : (
							<Typography sx={{ marginBottom: "20px" }}>
								There are no common instructors across all courses.
							</Typography>
						)}

						{rolePicked && rolePicked.length > 1 && (
							<Typography
								fontSize={18}
								textAlign={"center"}
							>
								Set Combined Class Name
							</Typography>
						)}
						{rolePicked && rolePicked.length > 1 && (
							<TextInput
								label="Combined Class Name"
								value={chosenCTitle || ""}
								onChange={textOnChange}
							/>
						)}
						{rolePicked && rolePicked.length > 1 && (
							<Typography>
								Suggested Name: (Click to autofill, you can edit after.){" "}
							</Typography>
						)}
						{rolePicked && rolePicked.length > 1 && suggestedCtitle && (
							<Card
								sx={{
									height: "30px",
									display: "flex",
									alignItems: "center",
									cursor: "pointer",
									"&:hover": {
										backgroundColor: "#e0e0e0",
									},
								}}
								onClick={handleSuggestion}
							>
								<CardContent>
									<Typography sx={{ marginTop: "8px" }}>
										{suggestedCtitle}
									</Typography>
								</CardContent>
							</Card>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							color="error"
							onClick={handleClose}
						>
							Cancel
						</Button>

						<Button
							onClick={handleSubmit}
							autoFocus
							disabled={!validChoices}
						>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{!teamTeachingAgree && props.list && props.list.length > 0 && (
				<Dialog
					open={props.open}
					onClose={handleClose}
					maxWidth="xl"
				>
					<DialogTitle textAlign={"center"}>
						{"Choose One Instructor"}
					</DialogTitle>

					<DialogContent
						sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
					>
						<DialogContentText sx={{ mx: "auto" }}>
							These instructors are common across all selected courses.
						</DialogContentText>
						{props.unwantedCombination && (
							<>
								<DialogContentText sx={{ maxWidth: "600px", color: "red" }}>
									WARNING: It seems like the selected classes might not be
									suitable for combining. Please do not combine LAB or SEM
									components with a LEC component that has the same students
									enrolled. Combining these sections causes issues with
									duplicate enrollments in the survey platform.
								</DialogContentText>
								<DialogContentText sx={{ maxWidth: "600px", color: "red" }}>
									Example: COURSE 201 LEC 100 and COURSE 201 LAB 100 should not
									be combined if the same students are enrolled in both and the
									same instructor teaches both components.
								</DialogContentText>
								<DialogContentText sx={{ maxWidth: "600px", color: "red" }}>
									Courses in Question:{" "}
								</DialogContentText>
								<DialogContentText sx={{ maxWidth: "600px", color: "red" }}>
									{lecCoursesCheck.length > 0 &&
										`${lecCoursesCheck[0].ctitle} - ${lecCoursesCheck[0].csize} students`}
								</DialogContentText>
								{otherCoursesCheck.length > 0 &&
									otherCoursesCheck.map((c) => {
										return (
											<DialogContentText
												sx={{
													maxWidth: "400px",
													color: "red",
												}}
											>
												{`${c.ctitle} - ${c.csize} students`}
											</DialogContentText>
										);
									})}
								<DialogContentText
									sx={{ maxWidth: "600px", color: "primary.main" }}
								>
									If you would still like to evaluate both sections, please
									submit them on separate requisitions (preferably with
									different questions).
								</DialogContentText>
							</>
						)}

						{commonInstructors.length > 0 ? (
							<FormControl component="fieldset">
								<FormGroup>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										defaultValue="female"
										name="radio-buttons-group"
										value={rolePicked || ""}
										onChange={onChange}
										sx={{ marginBottom: "20px" }}
									>
										{commonInstructors.map((role) => {
											return (
												<FormControlLabel
													key={role.empid}
													control={
														<Radio
															name={role.name}
															value={role.name}
														/>
													}
													label={role.name + " - " + role.class_role}
												/>
											);
										})}
									</RadioGroup>
								</FormGroup>
							</FormControl>
						) : (
							<Typography sx={{ marginBottom: "20px" }}>
								There are no common instructors across all courses.
							</Typography>
						)}
						{rolePicked && (
							<Typography
								fontSize={18}
								textAlign={"center"}
							>
								Set Combined Class Name
							</Typography>
						)}
						{rolePicked && (
							<TextInput
								label="Combined Class Name"
								value={chosenCTitle || ""}
								onChange={textOnChange}
							/>
						)}
						{rolePicked && (
							<Typography>
								Suggested Name: (Click to autofill, you can edit after.){" "}
							</Typography>
						)}
						{rolePicked && suggestedCtitle && (
							<Card
								sx={{
									height: "30px",
									display: "flex",
									alignItems: "center",
									cursor: "pointer",
									"&:hover": {
										backgroundColor: "#e0e0e0",
									},
								}}
								onClick={handleSuggestion}
							>
								<CardContent>
									<Typography sx={{ marginTop: "8px" }}>
										{suggestedCtitle}
									</Typography>
								</CardContent>
							</Card>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							color="error"
							onClick={handleClose}
						>
							Cancel
						</Button>

						<Button
							onClick={handleSubmit}
							autoFocus
							disabled={!validChoices}
						>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
}
