import { Typography, Paper } from "@mui/material";

export default function MidtermQuestionSets() {
	return (
		<div className="p-6">
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
				<Paper className="p-4">
					<Typography variant="h6">Suggested Questions</Typography>
					<Typography sx={{ width: "100%", marginTop: 2 }}>
						1. The instructor is helpful in answering questions. <br />
						2. Class time is used effectively. <br />
						3. The classroom is a comfortable learning environment. <br />
						4. The instructor presents the material in an interesting and
						helpful manner. <br />
						5. The instructor gives clear explanations. <br />
						6. The instructor stresses important points in lectures or
						discussions. <br />
						7. How can the instructor improve the teaching of this course?{" "}
						<br />
						8. Which aspects of the course do you like the best? <br />
						9. Which aspects of the course do you like the least? <br />
						10. Additional comments for the instructor?
					</Typography>
				</Paper>

				<Paper className="p-4">
					<Typography variant="h6">Start-Stop-Continue</Typography>
					<Typography sx={{ width: "100%", marginTop: 2 }}>
						1. What should I (the instructor) start doing? <br />
						2. What should I (the instructor) stop doing? <br />
						3. What should I (the instructor) continue doing?
					</Typography>
				</Paper>

				<Paper className="p-4">
					<Typography variant="h6">One Question</Typography>
					<Typography sx={{ width: "100%", marginTop: 2 }}>
						Please share any feedback on how the course is going so far.
					</Typography>
				</Paper>

				<Paper className="p-4">
					<Typography variant="h6">Three Open Questions</Typography>
					<Typography sx={{ width: "100%", marginTop: 2 }}>
						1. Which aspects of the course do you like the best? <br />
						2. Which aspects of the course do you like the least? <br />
						3. How can the instructor improve the teaching of this course?
					</Typography>
				</Paper>

				<Paper className="p-4">
					<Typography variant="h6">Custom</Typography>
					<Typography sx={{ width: "100%", marginTop: 2 }}>
						Choose your own questions! <br />
						You may also set custom scales for each question.
					</Typography>
				</Paper>
			</div>
		</div>
	);
}
