import { useQuery } from "react-query";
import { getAllItems } from "../components/Api/Getters";

export const useAllItemsQuery = (token, options = {}) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		staleTime: 10000 * 60, // 5 minutes
		...options, // spread the options object here
	};
	return useQuery(
		["allItemsQuery", token],
		() => getAllItems(token),
		queryOptions
	);
};
