import * as React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {
	GridFooterContainer,
	GridFooter,
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useContext, useState, useEffect } from "react";
import { deleteRequisition } from "../../../../../../components/Api/Deletes";
import PdfGeneration from "../../../../../../components/Utils/PdfGeneration";
import ConfirmDeleteDialog from "../Dialogs/DeleteRequisitionDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ClassesReleasedModal, GradesSubmittedModal } from "../Modals/ReleaseModals";
import { updateRequisitionDates } from "../../../../../../components/Api/Updates";
import ToolTipHelp from "../../../../../../components/Utils/ToolTipHelp";
import { Typography, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import isAuthenticated from "../../../../../../components/Utils/isAuthenticated";
import { RequisitionsContext } from "../../../../../../context/RequisitionContext";
import ListSelect from "../../../../../../components/Utils/ListSelect";

export default function ModifyRequisitionsTable(props) {
	let auth = isAuthenticated();
	const token = auth.token;
	const access = auth.access;
	const [checkboxSelectionState, setCheckboxSelectionState] = useState([]);
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const { requisitions, setRequisitions } = useContext(RequisitionsContext);
	const [rows, setRows] = useState([]);

	useEffect(() => {
		//get term number from term description
		if (props.termNumber && props.termNumber > 0) {
			const filteredRows = requisitions.filter((row) => {
				if (row.term === props.termNumber) {
					return true;
				} else {
					return false;
				}
			});
			setRows(filteredRows);
		}
	}, [props.termNumber, requisitions]);

	if (requisitions && requisitions.length > 0) {
		requisitions.forEach((requisition) => {
			if (requisition.classes && requisition.classes.length > 0) {
				requisition.term = requisition.classes[0].term;
			}

			if (requisition.itemList && requisition.itemList.length > 0) {
				let itemListString = "";
				requisition.itemList.forEach((item) => {
					itemListString += item.itemno + ",";
				});

				requisition.itemListString = itemListString.slice(0, -1);
			}
			requisition.start_date = dayjs(requisition.start_date).format("YYYY/MM/DD");
			requisition.end_date = dayjs(requisition.end_date).format("YYYY/MM/DD");
			if (requisition.requisitionModified === true) {
				updateRequisitionDates(
					token,
					requisition.reqnum,
					dayjs(requisition.start_date).format("YYYY-MM-DD"),
					dayjs(requisition.end_date).format("YYYY-MM-DD")
				);
			}
		});
	}

	useEffect(() => {
		if (requisitions && requisitions.length > 0) {
			requisitions.forEach((requisition) => {
				if (requisition.classes && requisition.classes.length > 0) {
					if (
						requisition.classes.some((classObj) => classObj.cls_rel === "Y") ||
						requisition.classes.every((classObj) => classObj.cls_rel === "Y")
					) {
						requisition.classes_released = true;
					}
					if (
						requisition.classes.every(
							(classObj) =>
								classObj.cls_rel === "N" ||
								classObj.cls_rel === null ||
								classObj.cls_rel === undefined ||
								classObj.cls_rel === ""
						)
					) {
						requisition.classes_released = false;
					}
				}
			});
			requisitions.forEach((requisition) => {
				if (requisition.classes.every((classObj) => classObj.cls_rel === "Y")) {
					requisition.classes_released_amount = "All";
				} else if (requisition.classes.some((classObj) => classObj.cls_rel === "Y")) {
					requisition.classes_released_amount = "Some";
				} else if (
					requisition.classes.every(
						(classObj) =>
							classObj.cls_rel === "N" ||
							classObj.cls_rel === null ||
							classObj.cls_rel === undefined ||
							classObj.cls_rel === ""
					)
				) {
					requisition.classes_released_amount = "No";
				}
			});
			requisitions.forEach((requisition) => {
				const fullyReleased = requisition.classes.every(
					(cls) => cls.rel_date !== null && cls.rel_date !== ""
				);
				const partiallyReleased = requisition.classes.some(
					(cls) => cls.rel_date !== null && cls.rel_date !== ""
				);
				const notReleased = requisition.classes.every(
					(cls) => cls.rel_date === null || cls.rel_date === ""
				);

				if (fullyReleased) {
					requisition.grades_released_amount = "All";
				}
				if (partiallyReleased) {
					let fakePartial = true;
					requisition.classes.forEach((cls) => {
						if ((cls.rel_date === null || cls.rel_date === "") && cls.qtally !== 0) {
							fakePartial = false;
						}
					});
					if (fakePartial) {
						requisition.grades_released_amount = "All";
					} else {
						requisition.grades_released_amount = "Some";
					}
				}
				if (notReleased) {
					requisition.grades_released_amount = "No";
				}
			});
		}
	}, [requisitions]);

	const handlePrintAllSelected = () => {
		let multi = true;
		checkboxSelectionState.forEach((req) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === req;
			});
			printRow(selectedReq, multi);
		});
	};

	const rowPicked = (row) => {
		props.setRowData(row);
		props.setOpenViewReqModal(true);
	};

	const handleDeleteDialog = (row) => {
		props.setRowData(row);
		setOpenDeleteDialog(true);
	};

	const deleteRow = (row) => {
		const updatedRows = [...requisitions];
		const index = updatedRows.findIndex((r) => r.reqnum === row.reqnum);
		updatedRows.splice(index, 1);
		setRequisitions(updatedRows);
		deleteRequisition(token, row.reqnum);
	};

	const handleDeleteAgree = () => {
		deleteRow(props.rowData);
		setOpenDeleteDialog(false);
	};

	const handleDeleteDisagree = () => {
		setOpenDeleteDialog(false);
	};

	const printRow = (row, multi) => {
		PdfGeneration(row, multi);
	};

	const setStartDate = (params) => {
		const startDateFromRow = params.value;
		const updatedRows = [...requisitions];
		const index = updatedRows.findIndex((r) => r.reqnum === params.row.reqnum);
		updatedRows[index].start_date = dayjs(startDateFromRow);
		updatedRows[index].requisitionModified = true;
		setRequisitions(updatedRows);
		return {
			...params.row,
			start_date: dayjs(startDateFromRow).format("YYYY-MM-DD"),
		};
	};

	const setEndDate = (params) => {
		const endDateFromRow = params.value;
		const updatedRows = [...requisitions];
		const index = updatedRows.findIndex((r) => r.reqnum === params.row.reqnum);
		updatedRows[index].end_date = dayjs(endDateFromRow);
		updatedRows[index].requisitionModified = true;
		setRequisitions(updatedRows);
		return {
			...params.row,
			end_date: dayjs(endDateFromRow).format("YYYY-MM-DD"),
		};
	};

	const renderReleaseButton = (params) => {
		return (
			<strong>
				<ClassesReleasedModal
					requisitions={props.requisitions}
					setRequisitions={props.setRequisitions}
					rowData={params.row}
					setOpenReleaseModal={props.setOpenReleaseModal}
					openReleaseModal={props.openReleaseModal}
				/>
			</strong>
		);
	};

	const renderGradesButton = (params) => {
		//if no classes in a row have cls_rel, disable button

		return (
			<strong>
				<GradesSubmittedModal
					requisitions={props.requisitions}
					setRequisitions={props.setRequisitions}
					rowData={params.row}
					setOpenReleaseModal={props.setOpenReleaseModal}
					openReleaseModal={props.openReleaseModal}
				/>
			</strong>
		);
	};

	const renderModifyButton = (params) => {
		return (
			<strong>
				<Button
					variant="outlined"
					sx={{ padding: "2px" }}
					color="primary"
					onClick={() => rowPicked(params.row)}
				>
					Modify
				</Button>
			</strong>
		);
	};

	const renderPrintButton = (params) => {
		let multi = false;
		return (
			<strong>
				<Button
					variant="contained"
					sx={{ padding: "2px" }}
					color="greysDark"
					onClick={() => printRow(params.row, multi)}
				>
					PDF
				</Button>
			</strong>
		);
	};

	const renderDeleteButton = (params) => {
		if (params.row.classes_released && !access.includes("ADMIN")) {
			return (
				<Tooltip
					title="You cannot delete a requisition if you have released a class within it."
					placement="right"
				>
					<strong>
						<Button
							disabled
							variant="contained"
							sx={{
								padding: "2px",
								maxWidth: "30px",
								minWidth: "30px",
							}}
							color="error"
							size="small"
						>
							<DeleteForeverIcon />
						</Button>
					</strong>
				</Tooltip>
			);
		} else {
			return (
				<strong>
					<Button
						variant="contained"
						sx={{
							padding: "3px",
							maxWidth: "30px",
							minWidth: "30px",
						}}
						color="error"
						size="small"
						onClick={() => handleDeleteDialog(params.row)}
					>
						<DeleteForeverIcon />
					</Button>
				</strong>
			);
		}
	};

	const renderModifyHeader = () => {
		return (
			<strong>
				<ToolTipHelp
					placement="top"
					title="Click on the Modify button to add or remove classes or items from a requisition. You cannot modify items if you have released a class within the requisition, and you cannot modify or delete a class once it has been released."
					sx={{
						marginY: "auto",
					}}
				/>
			</strong>
		);
	};

	const renderPrintHeader = () => {
		return (
			<strong>
				<ToolTipHelp
					placement="top"
					title="Click on the PDF button to generate a PDF of the requisition. This will download the chosen PDF to a specific location on your computer. If you would like to print multiple requisition PDFs, select them using the checkboxes and click on the Print All Selected button in the bottom left of the table."
					sx={{
						marginY: "auto",
					}}
				/>
			</strong>
		);
	};

	const renderReleaseHeader = () => {
		return (
			<strong>
				<ToolTipHelp
					placement="top"
					title="Click on the Release button to confirm that classes within the requisition are ready to be set up. This will allow TSS to begin setting up your requisition in CoursEval."
					sx={{
						marginY: "auto",
					}}
				/>
			</strong>
		);
	};

	const renderGradesHeader = () => {
		return (
			<strong>
				<ToolTipHelp
					placement="top"
					title="Click on the Grades button to confirm that final grades for a course have been submitted. This will allow TSS to begin generating reports for your chairs and professors."
					sx={{
						marginY: "auto",
					}}
				/>
			</strong>
		);
	};

	const renderDeleteHeader = () => {
		return (
			<strong>
				<ToolTipHelp
					placement="top"
					title="Click on the Delete button to delete a requisition. This is an irreversible action. You cannot delete a requisition if you have released a class within it."
					sx={{
						marginY: "auto",
					}}
				/>
			</strong>
		);
	};

	const CustomFooter = () => {
		return (
			<GridFooterContainer sx={{ display: "flex", justifyContent: "flex-start" }}>
				<Button variant="outlined" sx={{ marginLeft: "5px" }} onClick={handlePrintAllSelected}>
					Download All Selected
				</Button>
				<GridFooter sx={{ position: "absolute", right: "10px" }} />
			</GridFooterContainer>
		);
	};

	const CustomToolbar = () => {
		return (
			<GridToolbarContainer
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					p: 1,
					borderBottom: "1px solid #e0e0e0",
				}}
			>
				<Box sx={{ display: "flex", gap: "10px" }}>
					<ListSelect
						className="termSelect"
						label="Term"
						list={props.terms}
						width="200px"
						size="small"
						marginBottom="0px"
						value={props.termValue || ""}
						onChange={(event) => props.setTermValue(event.target.value)}
					/>
				</Box>
				<Typography fontSize={18}>Manage Requisitions</Typography>
				<Box
					sx={{
						border: "1px solid #bdbdbd",
						borderRadius: "5px",
						display: "flex",
						gap: "5px",
						p: 0.5,
					}}
				>
					<GridToolbarColumnsButton sx={{ marginLeft: "5px" }} />
					<GridToolbarDensitySelector />
					<GridToolbarFilterButton />
					<GridToolbarExport sx={{ marginRight: "5px" }} />
				</Box>
			</GridToolbarContainer>
		);
	};
	const columnsAdmin = [
		{
			field: "reqnum",
			headerName: "ReqNum",
			width: 70,
			renderCell: (params) => {
				return (
					<Typography fontSize={15} fontWeight={600}>
						{params.row.reqnum}
					</Typography>
				);
			},
		},
		{
			field: "rqdate",
			headerName: "Requested",
			renderCell: (params) => {
				if (access.includes("ADMIN")) {
					return dayjs(params.row.rqdate).format("DD/MMM/YY");
				} else {
					return dayjs(params.row.start_date).format("DD/MM/YY");
				}
			},
			width: 100,
		},
		{
			field: "raddr1",
			headerName: "Name",
			width: 180,
			flex: 0.5,
		},
		{
			field: "raddr2",
			flex: 0.5,
			headerName: "Department",
			width: 120,
		},
		{
			field: "term",
			headerName: "Term",
			width: 80,
		},
		{
			field: "blkid1",
			headerName: "Block",
			width: 90,
			renderCell: (params) => {
				if (params.row.blkid1 !== "") {
					return <Typography fontSize={15}>{params.row.blkid1}</Typography>;
				} else {
					return <Typography fontSize={15}>{params.row.blkid3}</Typography>;
				}
			},
		},
		{
			field: "",
			headerName: "Team?",
			width: 80,
			renderCell: (params) => {
				if (params.row.classes && params.row.classes.length > 0) {
					//if there are duplicate classids, then it is a team
					let classids = [];
					params.row.classes.forEach((cls) => {
						classids.push(cls.classid);
					});
					const uniqueClassids = [...new Set(classids)];
					if (uniqueClassids.length < classids.length) {
						return (
							<Typography fontSize={15} fontWeight={600}>
								Yes
							</Typography>
						);
					} else {
						return (
							<Typography fontSize={15} fontWeight={600}>
								No
							</Typography>
						);
					}
				} else {
					return (
						<Typography fontSize={15} fontWeight={600}>
							No
						</Typography>
					);
				}
			},
			align: "center",
			headerAlign: "center",
		},
		{
			field: "start_date",
			headerName: "Start Date",
			type: "date",
			width: 100,
			valueSetter: (params) => setStartDate(params),

			renderCell: (params) => {
				if (access.includes("ADMIN")) {
					return dayjs(params.row.start_date).format("DD/MMM/YY");
				} else {
					return dayjs(params.row.start_date).format("DD/MM/YY");
				}
			},
			editable: true,
		},
		{
			field: "end_date",
			headerName: "End Date",
			type: "date",
			width: 100,
			valueSetter: (params) => setEndDate(params),
			renderCell: (params) => {
				if (access.includes("ADMIN")) {
					return dayjs(params.row.end_date).format("DD/MMM/YY");
				} else {
					return dayjs(params.row.start_date).format("DD/MM/YY");
				}
			},
			editable: true,
		},

		{
			field: "view",
			headerName: " ",
			align: "center",
			width: 80,
			renderCell: renderModifyButton,
			renderHeader: renderModifyHeader,
			headerAlign: "center",
			sortable: false,
			disableColumnMenu: true,
		},

		{
			field: "print",
			headerName: " ",
			align: "center",
			width: 80,
			renderCell: renderPrintButton,
			renderHeader: renderPrintHeader,
			headerAlign: "center",
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "classes_released_amount",
			headerName: "Released",
			width: 80,
			align: "center",
			renderCell: (params) => {
				if (params.row.classes_released_amount === "All") {
					return (
						<Typography color="green" fontSize={15} fontWeight={600}>
							{params.row.classes_released_amount}
						</Typography>
					);
				} else if (params.row.classes_released_amount === "Some") {
					return (
						<Typography color="secondary.dark" fontSize={15} fontWeight={600}>
							{params.row.classes_released_amount}
						</Typography>
					);
				} else if (params.row.classes_released_amount === "No") {
					return (
						<Typography color="red" fontSize={15} fontWeight={600}>
							{params.row.classes_released_amount}
						</Typography>
					);
				} else {
					return (
						<Typography color="red" fontSize={15} fontWeight={600}>
							?
						</Typography>
					);
				}
			},
		},

		{
			field: "release",
			headerName: " ",
			width: 80,
			renderCell: renderReleaseButton,
			renderHeader: renderReleaseHeader,
			headerAlign: "center",
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "grades_released_amount",
			headerName: "Grades",
			width: 80,
			align: "center",
			renderCell: (params) => {
				if (params.row.grades_released_amount === "All") {
					return (
						<Typography color="green" fontSize={15} fontWeight={600}>
							{params.row.grades_released_amount}
						</Typography>
					);
				} else if (params.row.grades_released_amount === "Some") {
					return (
						<Typography color="secondary.dark" fontSize={15} fontWeight={600}>
							{params.row.grades_released_amount}
						</Typography>
					);
				} else if (params.row.grades_released_amount === "No") {
					return (
						<Typography color="red" fontSize={15} fontWeight={600}>
							{params.row.grades_released_amount}
						</Typography>
					);
				}
			},
		},

		{
			field: "grades",
			headerName: " ",
			align: "center",
			width: 80,
			renderCell: renderGradesButton,
			renderHeader: renderGradesHeader,
			headerAlign: "center",
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "delete",
			headerName: " ",
			align: "center",
			width: 20,
			renderCell: renderDeleteButton,
			renderHeader: renderDeleteHeader,
			headerAlign: "center",
			sortable: false,
			disableColumnMenu: true,
		},
	];

	const columns = [
		{
			field: "reqnum",
			headerName: "ReqNum",
			width: 80,
			renderCell: (params) => {
				return (
					<Typography fontSize={15} fontWeight={600}>
						{params.row.reqnum}
					</Typography>
				);
			},
			flex: 0.2,
		},
		{
			field: "term",
			headerName: "Term",
			width: 60,
		},
		{
			field: "rqdate",
			headerName: "Requested",
			renderCell: (params) => {
				return dayjs(params.row.rqdate).format("DD-MMM-YYYY");
			},
			width: 110,
		},
		{
			field: "blkid1",
			headerName: "Block",
			width: 100,
			renderCell: (params) => {
				if (params.row.blkid1 !== "") {
					return <Typography fontSize={15}>{params.row.blkid1}</Typography>;
				} else {
					return <Typography fontSize={15}>{params.row.blkid3}</Typography>;
				}
			},
			flex: 0.2,
		},
		{
			field: "ttItemCount",
			headerName: "Team?",
			width: 80,
			renderCell: (params) => {
				if (params.row.classes && params.row.classes.length > 0) {
					//if there are duplicate classids, then it is a team
					let classids = [];
					params.row.classes.forEach((cls) => {
						classids.push(cls.classid);
					});
					const uniqueClassids = [...new Set(classids)];
					if (uniqueClassids.length < classids.length) {
						return (
							<Typography fontSize={15} fontWeight={600}>
								Yes
							</Typography>
						);
					} else {
						return (
							<Typography fontSize={15} fontWeight={600}>
								No
							</Typography>
						);
					}
				} else {
					return (
						<Typography fontSize={15} fontWeight={600}>
							No
						</Typography>
					);
				}
			},
			align: "center",
			headerAlign: "center",
			flex: 0.2,
		},
		{
			field: "start_date",
			headerName: "Start Date",
			type: "date",
			width: 100,
			valueSetter: (params) => setStartDate(params),
			renderCell: (params) => {
				return dayjs(params.row.start_date).format("DD-MMM-YYYY");
			},
			editable: true,
			flex: 0.2,
		},
		{
			field: "end_date",
			headerName: "End Date",
			type: "date",
			width: 100,
			valueSetter: (params) => setEndDate(params),
			renderCell: (params) => {
				return dayjs(params.row.end_date).format("DD-MMM-YYYY");
			},
			editable: true,
			flex: 0.2,
		},

		{
			field: "view",
			headerName: " ",
			align: "center",
			width: 80,
			renderCell: renderModifyButton,
			renderHeader: renderModifyHeader,
			headerAlign: "center",
			sortable: false,
			disableColumnMenu: true,
		},

		{
			field: "print",
			headerName: " ",
			align: "center",
			width: 80,
			renderCell: renderPrintButton,
			renderHeader: renderPrintHeader,
			headerAlign: "center",
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "classes_released_amount",
			headerName: "Released",
			width: 80,
			align: "center",
			renderCell: (params) => {
				if (params.row.classes_released_amount === "All") {
					return (
						<Typography color="green" fontSize={15} fontWeight={600}>
							{params.row.classes_released_amount}
						</Typography>
					);
				} else if (params.row.classes_released_amount === "Some") {
					return (
						<Typography color="secondary.dark" fontSize={15} fontWeight={600}>
							{params.row.classes_released_amount}
						</Typography>
					);
				} else if (params.row.classes_released_amount === "No") {
					return (
						<Typography color="red" fontSize={15} fontWeight={600}>
							{params.row.classes_released_amount}
						</Typography>
					);
				}
			},
		},
		{
			field: "release",
			headerName: " ",
			width: 80,
			renderCell: renderReleaseButton,
			renderHeader: renderReleaseHeader,
			headerAlign: "center",
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "grades_released_amount",
			headerName: "Grades",
			width: 80,
			align: "center",
			renderCell: (params) => {
				if (params.row.grades_released_amount === "All") {
					return (
						<Typography color="green" fontSize={15} fontWeight={600}>
							{params.row.grades_released_amount}
						</Typography>
					);
				} else if (params.row.grades_released_amount === "Some") {
					return (
						<Typography color="secondary.dark" fontSize={15} fontWeight={600}>
							{params.row.grades_released_amount}
						</Typography>
					);
				} else if (params.row.grades_released_amount === "No") {
					return (
						<Typography color="red" fontSize={15} fontWeight={600}>
							{params.row.grades_released_amount}
						</Typography>
					);
				}
			},
		},
		{
			field: "grades",
			headerName: " ",
			align: "center",
			width: 80,
			renderCell: renderGradesButton,
			renderHeader: renderGradesHeader,
			headerAlign: "center",
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "delete",
			headerName: " ",
			align: "center",
			width: 20,
			renderCell: renderDeleteButton,
			renderHeader: renderDeleteHeader,
			headerAlign: "center",
			sortable: false,
			disableColumnMenu: true,
		},
	];
	return (
		<div style={{ display: "flex", height: "100%", width: "100%" }}>
			<DataGrid
				loading={props.requisitionsLoading || props.requisitionsFetching}
				sx={{
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "Nne !important",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
						width: "0.4em",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
						background: "#f1f1f1",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
						backgroundColor: "#888",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
						background: "#555",
					},

					borderRadius: "1px 1px 4px 4px",
				}}
				autoPageSize
				disableSelectionOnClick
				checkboxSelection
				onSelectionModelChange={(newSelection) => {
					setCheckboxSelectionState(newSelection);
				}}
				components={{
					Footer: CustomFooter,
					Toolbar: CustomToolbar,
					LoadingOverlay: LinearProgress,
				}}
				rows={rows}
				density="compact"
				columns={access.includes("ADMIN") ? columnsAdmin : columns}
				initialState={{
					pagination: {
						paginationModel: { page: 0, pageSize: 5 },
					},
				}}
				pageSizeOptions={[5, 10]}
				getRowId={(row) => row.reqnum}
			/>
			<ConfirmDeleteDialog
				row={props.rowData}
				open={openDeleteDialog}
				setOpenDeleteDialog={setOpenDeleteDialog}
				handleDeleteAgree={handleDeleteAgree}
				handleDeleteDisagree={handleDeleteDisagree}
			/>
		</div>
	);
}
