import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useState, useContext } from "react";
import ClassIdsTable from "../../../../components/ClassIdsTable";
import ClassesTable from "../../../../components/ClassesTable";
import AutocompleteComponent from "../../../../../../components/Utils/AutocompleteComponent";
import ListSelect from "../../../../../../components/Utils/ListSelect";
import { TeamTeachingContext } from "../../../../../../context/RequisitionContext";
import { useDepartmentsQuery } from "../../../../../../Hooks/useDepartmentsQuery";
import isAuthenticated from "../../../../../../components/Utils/isAuthenticated";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90%",
	height: "98%",
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	display: "flex",
	flexDirection: "column",
};

export default function RequisitionModalClasses(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const [autocompleteValue, setAutocompleteValue] = useState("");
	const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
	const [combineClasses, setCombineClasses] = useState([]);
	const teamTeachingAgree = useContext(TeamTeachingContext);
	const [open, setOpen] = useState(false);
	const [checkboxSelectionState, setCheckboxSelectionState] = useState([]);

	const { setRaddr2, setDeptId } = props;
	const { data: departments } = useDepartmentsQuery(token);

	useEffect(() => {
		let displayName = "";

		if (autocompleteValue && autocompleteValue.display_name !== undefined) {
			displayName = autocompleteValue.display_name;
		}
		setRaddr2(displayName);
	}, [autocompleteValue, setRaddr2]);

	useEffect(() => {
		if (autocompleteValue) {
			const deptid = autocompleteValue.key;
			setDeptId(deptid);
		}
	}, [autocompleteValue, setDeptId]);

	useEffect(() => {
		if (props.deptFromUser && departments && departments.length > 0) {
			setAutocompleteValue(
				departments.find((dept) => dept.key === Number(props.deptFromUser))
			);
		}
	}, [props.deptFromUser, departments]);

	const handleOpen = () => setOpen(true);

	const handleClose = () => {
		setOpen(false);
	};

	const handleTermChange = (event) => {
		props.setTermValue(event.target.value);
	};

	return (
		<div>
			<Button
				variant="outlined"
				onClick={handleOpen}
				disabled={!props.itemsChosenBool}
				sx={{ width: "500px" }}
			>
				Choose Classes
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ display: "flex" }}
			>
				<Box sx={style}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							p: 1,
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "baseline",
								width: "600px",
								gap: "10px",
								marginTop: "5px",
							}}
						>
							<Box sx={{ width: "295px" }}>
								<AutocompleteComponent
									options={departments ? departments : []}
									variant="standard"
									marginTop="0px"
									marginBottom="0px"
									value={autocompleteValue}
									setValue={setAutocompleteValue}
									inputValue={autocompleteInputValue}
									setInputValue={setAutocompleteInputValue}
									label="Department"
									autoHighlight={true}
								/>
							</Box>
							<Box sx={{ width: "295px" }}>
								<ListSelect
									value={props.termValue ? props.termValue : ""}
									list={
										props.termsList &&
										Array.isArray(props.termsList) &&
										props.termsList.length > 0
											? props.termsList
											: []
									}
									variant="standard"
									label="Term"
									onChange={handleTermChange}
								/>
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							width: "98%",
							height: "85%",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					>
						<Box
							sx={{
								height: "65%",
							}}
						>
							<ClassIdsTable
								inputValue={autocompleteInputValue}
								termValue={props.termValue}
								combineClasses={combineClasses}
								setCombineClasses={setCombineClasses}
								teamTeachingAgree={teamTeachingAgree}
								filterKey={props.filterKey}
								setFilterKey={props.setFilterKey}
								checkboxSelectionState={checkboxSelectionState}
								setCheckboxSelectionState={setCheckboxSelectionState}
								source="AddRequisition"
							/>
						</Box>
						<Box
							sx={{
								height: "35%",
							}}
						>
							<ClassesTable
								teamTeachingAgree={teamTeachingAgree}
								startValueDefault={props.startValueDefault}
								endValueDefault={props.endValueDefault}
								minDate={props.minDate}
								maxDate={props.maxDate}
							/>
						</Box>
					</Box>
					<Button
						variant="contained"
						onClick={handleClose}
						sx={{
							m: 1,
							width: "98%",
							alignSelf: "center",
						}}
					>
						Done
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
