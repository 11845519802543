import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl, FormLabel } from "@mui/material";

export function DatePickerComponent(props) {
	const [error, setError] = React.useState(null);

	const errorMessage = React.useMemo(() => {
		switch (error) {
			case "maxDate":
			case "minDate": {
				return "Please select valid dates.";
			}

			case "invalidDate": {
				if (props.confirm) {
					return "";
				} else {
					return "Please select valid dates.";
				}
			}

			default: {
				return "";
			}
		}
	}, [error]);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div className="p-1 bg-gray-100 shadow-md border-[1px] rounded-md">
				<FormControl>
					<p className="text-zinc-900 text-sm font-semibold tracking-wide">
						{props.label}
					</p>
					<DatePicker
						disabled={props.disabled}
						value={props.value || null}
						views={["month", "day"]}
						format="DD/MMM/YY"
						onChange={props.onChange}
						// onError={(newError) => setError(newError)}
						slotProps={{
							textField: {
								helperText: errorMessage,
								sx: {
									width: props.width ? props.width : "245px",
									marginLeft: props.marginLeft,
									outline: props.error ? "1px solid red" : "none",
									borderRadius: props.error ? "5px" : "none",
									backgroundColor: "#fff",
								},
							},
						}}
						minDate={props.minDate}
						maxDate={props.maxDate}
					/>
				</FormControl>
			</div>
		</LocalizationProvider>
	);
}
