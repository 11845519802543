import * as React from "react";
import Switch from "@mui/material/Switch";
const label = { inputProps: { "aria-label": "Color switch demo" } };

export default function ToggleExclude(props) {
	const handleClick = () => {
		if (props.disabled) return;
		props.handleToggleExclude();
	};

	return (
		<div
			className={`h-full w-1/2 flex flex-col justify-center  items-center rounded-md ${
				props.disabled
					? "hover:cursor-default"
					: "hover:cursor-pointer hover:ring-2 hover:ring-gray-300"
			}  ${
				props.row.evaluate === "No"
					? "bg-red-100 border-2 border-red-500"
					: "bg-white border-2"
			}`}
			onClick={handleClick}
		>
			<p
				className={`${
					props.row.evaluate === "No"
						? "text-red-600 "
						: "text-zinc-900 text-sm font-semibold tracking-wide"
				}`}
			>
				Exclude From SPOT
			</p>
			<Switch
				{...label}
				disabled={props.disabled}
				checked={
					props.row && props.row.evaluate
						? props.row.evaluate === "No"
							? true
							: false
						: false
				}
				onChange={(e) => {
					props.handleToggleExclude(e.target.checked);
				}}
				color="error"
			/>
		</div>
	);
}
