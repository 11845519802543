import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useState, useContext } from "react";
import AutocompleteComponent from "../../../../../../components/Utils/AutocompleteComponent";
import ListSelect from "../../../../../../components/Utils/ListSelect";
import {
	TeamTeachingContext,
	TermContext,
} from "../../../../../../context/RequisitionContext";

import { useDepartmentsQuery } from "../../../../../../Hooks/useDepartmentsQuery";
import ClassIdsTable from "../../../../components/ClassIdsTable";
import ClassesTable from "../../../../components/ClassesTable";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90vw",
	height: "95vh",
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	display: "flex",
	flexDirection: "column",
};

export default function AddClassesModal(props) {
	const [token] = useState(sessionStorage.getItem("token"));
	const [autocompleteValue, setAutocompleteValue] = useState("");
	const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
	const [combineClasses, setCombineClasses] = useState([]);
	const teamTeachingAgree = useContext(TeamTeachingContext);
	const { setDeptId } = props;
	const [checkboxSelectionState, setCheckboxSelectionState] = useState([]);

	useEffect(() => {
		if (autocompleteValue) {
			const deptid = autocompleteValue.key;
			setDeptId(deptid);
		}
	}, [autocompleteValue, setDeptId]);

	useEffect(() => {
		if (props.termValue && props.termValue.length > 0) {
			setAutocompleteValue(props.termValue);
		}
	}, [props.termValue]);

	const { data: departments } = useDepartmentsQuery(token);

	const [departmentsList, setDepartmentsList] = useState([]);

	useEffect(() => {
		if (departments) {
			setDepartmentsList(departments);
		}
	}, [departments]);

	const handleClose = () => {
		setAutocompleteValue("");
		setAutocompleteInputValue("");
		props.setOpen(false);
	};

	const handleTermChange = (event) => {
		props.setTermValue(event.target.value);
	};
	return (
		<div>
			<Modal
				open={props.open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ display: "flex" }}
			>
				<Box sx={style}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							height: "7%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "baseline",
								width: "600px",
								gap: "10px",
								marginTop: "5px",
							}}
						>
							<Box sx={{ width: "295px" }}>
								<AutocompleteComponent
									variant="standard"
									marginTop="0px"
									marginBottom="0px"
									options={departmentsList ? departmentsList : []}
									value={autocompleteValue}
									setValue={setAutocompleteValue}
									inputValue={autocompleteInputValue}
									setInputValue={setAutocompleteInputValue}
									label="Department"
								/>
							</Box>
							<Box sx={{ width: "295px" }}>
								<ListSelect
									value={props.termValue || ""}
									list={props.terms}
									variant="standard"
									label="Term"
									onChange={handleTermChange}
								/>
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							width: "98%",
							height: "85%",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					>
						<Box sx={{ height: "65%" }}>
							<ClassIdsTable
								inModify
								termValue={props.termValue}
								combineClasses={combineClasses}
								setCombineClasses={setCombineClasses}
								teamTeachingAgree={teamTeachingAgree}
								checkboxSelectionState={checkboxSelectionState}
								setCheckboxSelectionState={setCheckboxSelectionState}
							/>
						</Box>
						<Box sx={{ height: "35%" }}>
							<ClassesTable
								rowData={props.rowData}
								teamTeachingAgree={teamTeachingAgree}
								inModify
								inModifySecond
								startValueDefault={props.startValueDefault}
								endValueDefault={props.endValueDefault}
								minDate={props.minDate}
								maxDate={props.maxDate}
							/>
						</Box>
					</Box>
					<Button
						variant="contained"
						onClick={handleClose}
						sx={{
							marginTop: "15px",
							marginBottom: "0px",
							width: "98%",
							alignSelf: "center",
						}}
					>
						Done
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
