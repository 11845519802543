import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import RolePicker from "./RolePicker";
import ListSelect from "../../../../components/Utils/ListSelect";
import ClassAdjustAddInstructor from "./ClassAdjustAddInstructor";
import { DeleteForever } from "@mui/icons-material";

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 0 }}>{children}</Box>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function TabsClassAdjustment(props) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleCombineClass = () => {
		props.handleCombineClass(props.row);
	};
	//console.log(props.row.roles);
	return (
		<Box sx={{ width: "100%", height: "42%" }} className="border-2 rounded-md p-1 pb-2">
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					<Tab
						label="Instructors"
						{...a11yProps(0)}
						disabled={props.disabled || props.combiningMode}
					/>
					<Tab
						label="Combining"
						{...a11yProps(1)}
						disabled={props.disabled || props.combiningMode}
					/>
					<Tab label="Instances" {...a11yProps(2)} disabled={props.disabled} />
				</Tabs>
			</Box>
			<div>
				{props.disabled ? (
					<div></div>
				) : (
					<div>
						<CustomTabPanel value={value} index={0}>
							<div className="py-2">
								<ol
									className={`gap-2 flex flex-col ${
										props.addInstructor ? "max-h-[80px]" : "max-h-[140px]"
									} overflow-auto`}
								>
									{props.row.roles.map((role) => (
										<li
											key={role.empid}
											className="border-2 rounded-md px-2 py-[6px] grid grid-cols-5 gap-4 font-semibold bg-gray-100 shadow-sm max-h-[70px] min-h-[40px] overflow-hidden flex justify-center items-center"
										>
											<p
												className="col-span-3 text-sm truncate max-w-[22ch] flex items-center h-[30px]"
												title={
													role.name
														? role.name
														: role.user.first_name.concat(" ").concat(role.user.last_name)
												} // Shows the full text on hover
											>
												{role.name && role.name.length > 22
													? `${role.name.substring(0, 22)}...`
													: role.name
													? role.name
													: `${role.user.last_name}, ${role.user.first_name}`}
											</p>

											{props.row.roles.length > 1 ? (
												<div className="col-span-2 grid grid-cols-2 gap-2">
													<RolePicker
														list={props.roles}
														label="Role"
														className="col-span-1  bg-white max-h-[70px]"
														width="100%"
														size="small"
														marginBottom="0px"
														value={role.class_role || ""}
														onChange={(event) => props.handleChangeRole(event, role, props.row)}
													/>
													<button
														onClick={(event) => props.deleteInstructor(event, role)}
														className="   max-h-[30px]  text-red-700 rounded-md hover:text-red-500  my-auto"
													>
														<DeleteForever />
													</button>
												</div>
											) : (
												<div className="col-span-2">
													<ListSelect
														list={props.roles}
														label="Role"
														className=" my-auto "
														width="100%"
														size="small"
														marginBottom="0px"
														value={role.class_role || ""}
														onChange={(event) => props.handleChangeRole(event, role, props.row)}
													/>
												</div>
											)}
										</li>
									))}
								</ol>
								<div className="gap-2 flex flex-col ">
									{props.addInstructor ? (
										<ClassAdjustAddInstructor
											rows={props.rows}
											setRows={props.setRows}
											selectedClass={props.selectedClass}
											setAddInstructor={props.setAddInstructor}
											setUnsavedChanges={props.setUnsavedChanges}
										/>
									) : (
										<button
											onClick={() => props.setAddInstructor(true)}
											className="shadow-md hover:ring-2 hover:ring-gray-300 border-2 rounded-md  py-1 border-green-700 bg-green-700 shadow-inner  text-gray-100 text-center w-full hover:bg-green-800 hover:border-green-800 mt-2"
										>
											(+) Add Instructor
										</button>
									)}
								</div>
							</div>
						</CustomTabPanel>

						<CustomTabPanel
							value={value}
							index={1}
							disabled={props.selectedClass.roles.length === 0}
						>
							<div className=" flex flex-col justify-center h-full w-full items-center ">
								{props.selectedClass && props.selectedClass.combine.length > 0 ? (
									<div className="flex flex-col   h-[280px] w-full ">
										<div className=" text-sm flex  gap-1 px-4 pt-2 ">
											Class currently combined with:
										</div>
										<div className="grid grid-cols-3 gap-2 p-4 h-full  ">
											{props.selectedClass.combine.length > 0 &&
												props.selectedClass.combine.split(",").map((combine) => (
													<p key={combine} className="font-bold ">
														{combine}
													</p>
												))}
										</div>
										<button
											onClick={props.undoCombine}
											className={`${
												props.selectedClass.allowUndo
													? "border-red-700 bg-red-600 hover:bg-red-700 hover:border-red-800 hover:ring-2 hover:ring-gray-300 text-white "
													: "border-gray-400 bg-gray-300 text-gray-400 hover:cursor-not-allowed aria-disabled:"
											}shadow-md  border-2 rounded-md px-2 py-2  self-end  text-center w-full `}
										>
											Undo Combine
										</button>
										{!props.selectedClass.allowUndo && (
											<p className=" text-sm flex justify-between max-w-60 text-red-600">
												You cannot undo a class that was combined from Campus Solutions. Only
												manually combined classes can be undone.
											</p>
										)}
									</div>
								) : props.combiningMode ? (
									<div className="flex flex-col justify-between">
										<div className="text-sm p-1 mt-2 bg-gray-100 rounded-md border-2 border-gray-300 flex flex-col gap-2">
											<span className="font-bold text-md">
												Combining {props.selectedClass.ctitle}
											</span>
											<p>Select a suitable class from the table on the right to combine</p>
											<p>
												The selected class must have an instructor in common with the class you are
												combining with.
											</p>
										</div>
										<div className="flex gap-2 mt-2">
											{props.classesForCombining.length > 1 &&
												props.classesForCombining.length < 11 && (
													<button
														onClick={() => props.setOpenClassAdjustCombineDialog(true)}
														className="shadow-md hover:ring-2 hover:ring-gray-300 border-2 rounded-md px-2 border-green-900 bg-green-800  text-white text-center w-full hover:bg-green-900 hover:border-green-950"
													>
														Combine ({props.checkboxSelectionState.length})
													</button>
												)}

											<button
												onClick={props.handleCancelCombine}
												className="shadow-md hover:ring-2  hover:ring-gray-300 border-2 rounded-md px-2 py-2 border-red-700 bg-red-600 shadow-inner  text-white text-center w-full hover:bg-red-700 hover:border-red-800"
											>
												Cancel
											</button>
										</div>
									</div>
								) : (
									<button
										onClick={handleCombineClass}
										className="shadow-md hover:ring-2 hover:ring-gray-300 border-2 rounded-md px-2 py-12 mt-8 border-blue-700 bg-blue-600 shadow-inner  text-gray-100 text-center w-full hover:bg-blue-700 h-full hover:border-blue-800"
									>
										Combine Class
									</button>
								)}
							</div>
						</CustomTabPanel>
						<CustomTabPanel value={value} index={2}>
							<div className="pt-2 max-h-[130px]">
								{props.selectedClass.original !== null ? (
									<div className="p-2 border-2 rounded-md shadow-md text-red-700">
										You cannot instance an already instanced class. Please instance the parent class
										that this originated from.
									</div>
								) : (
									<button
										onClick={props.handleOpenAddInstancedClass}
										className="shadow-md hover:ring-2 hover:ring-gray-300 border-2 rounded-md px-2 py-2 border-green-800 bg-green-700 text-white text-center w-full hover:bg-green-800 hover:border-green-900"
									>
										( + ) Add Instanced Class
									</button>
								)}
							</div>
						</CustomTabPanel>
					</div>
				)}
			</div>
		</Box>
	);
}
