import { Modal, Box, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { postClassIdsAndRoles } from "../../../../components/Api/Post";
import { useMutation, queryClient } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import isAuthenticated from "../../../../components/Utils/isAuthenticated";
import ClassAdjustAddInstructor from "./ClassAdjustAddInstructor";
import InstructorCell from "./InstructorCell";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90vw",
	height: "61%",
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	display: "flex",
	flexDirection: "column",
};

const prependValues = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function InstanceClassAdjustModal(props) {
	const [rows, setRows] = useState([]);

	const [openInstructorDialog, setOpenInstructorDialog] = useState(false);
	const [classForInstance, setClassForInstance] = useState({});
	const [listOfAddedClasses, setListOfAddedClasses] = useState([]);

	useEffect(() => {
		if (props.selectedClass) {
			let listOfClasses = [];
			listOfClasses.push(props.selectedClass);
			if (props.rows && props.rows.length > 0) {
				props.rows.forEach((row) => {
					if (row.original === props.selectedClass.classid) {
						listOfClasses.push(row);
					}
				});
			}
			setRows(listOfClasses);
		}
	}, [props.selectedClass, props.rows]);

	const auth = isAuthenticated();
	const token = auth.token;

	function getFirstDigit(classId) {
		classId = classId.toString();
		const classId0 = classId[0];
		return Number(classId0);
	}

	function getLast4DigitsOfClassId(classId) {
		classId = classId.toString();
		const classId1 = classId[1];
		const classId2 = classId[2];
		const classId3 = classId[3];
		const classId4 = classId[4];
		return Number(classId1 + classId2 + classId3 + classId4);
	}
	//console.log(props.rows);
	function getNextAvailableInstanceId(classid, existingClassIds) {
		const listOfBadDigits = [];
		const firstDigit = getFirstDigit(classid);
		listOfBadDigits.push(firstDigit);
		existingClassIds?.forEach((existingClassId) => {
			const last4DigitsExisting = getLast4DigitsOfClassId(existingClassId);
			const last4DigitsCurrent = getLast4DigitsOfClassId(classid);
			if (last4DigitsExisting === last4DigitsCurrent) {
				const firstDigit = getFirstDigit(existingClassId);
				listOfBadDigits.push(firstDigit);
			}
		});
		listOfBadDigits.sort((a, b) => a - b);
		const diff = prependValues.filter((x) => !listOfBadDigits.includes(x));
		const last4Digits = getLast4DigitsOfClassId(classid);
		const nextAvailableId = diff[0] * 10000 + last4Digits;
		return nextAvailableId;
	}

	const handleAddInstantClassSame = () => {
		//console.log("something");
		const classid = props.selectedClass.classid;
		const newClassidInt = getNextAvailableInstanceId(
			props.selectedClass.classid,
			props.rows?.map((c) => c.classid)
		);
		const newClass = {
			...props.selectedClass,
			classid: newClassidInt,
			original: classid,
			acyear: "2023/24",
			new: true,
		};

		setRows([...rows, newClass]);
		const updatedPropRows = [...props.rows];
		updatedPropRows.push(newClass);
		props.setRows(updatedPropRows);
		setListOfAddedClasses([...listOfAddedClasses, newClassidInt]);
	};

	const handleAddInstantClassDifferent = () => {
		const classid = props.selectedClass.classid;
		const newClassidInt = getNextAvailableInstanceId(
			props.selectedClass.classid,
			props.rows.map((c) => c.classid)
		);

		const newClass = {
			...props.selectedClass,
			classid: newClassidInt,
			original: classid,
			teamsize: 0,
			acyear: "2023/24",
			roles: [],
			new: true,
		};
		const updatedPropRows = [...props.rows];
		updatedPropRows.push(newClass);
		props.setRows(updatedPropRows);

		const oldRows = [...rows];

		setRows([...oldRows, newClass]);
		setClassForInstance(newClass);
		setListOfAddedClasses([...listOfAddedClasses, newClassidInt]);
		setOpenInstructorDialog(true);
	};

	async function getNewRows() {
		let listOfNewRows = [];
		props.rows.forEach((row) => {
			if (row.new) {
				listOfNewRows.push(row);
			}
		});
		return listOfNewRows;
	}
	const postClassIdsRolesMutation = useMutation(({ token, listOfNewRows }) =>
		postClassIdsAndRoles({ token, listOfNewRows })
	);

	const handleSubmit = async () => {
		const listOfNewRows = await getNewRows();

		postClassIdsRolesMutation.mutateAsync(
			{ token, listOfNewRows },
			{
				onSuccess: (data, error) => {
					setListOfAddedClasses([]);

					props.setOpen(false);
				},
			}
		);
		props.setOpen(false);
	};

	const handleClose = (event, reason) => {
		if (reason && reason === "backdropClick") {
			return;
		} else {
			if (props.rows && props.rows.length > 0) {
				//remove all new rows
				const rowsCopy = [...props.rows];
				const newRows = rowsCopy.filter(
					(row) => !listOfAddedClasses.includes(row.classid)
				);
				props.setRows(newRows);
			}
			props.setOpen(false);
		}
	};

	const handleDelete = (params) => {
		const updatedRows = [...rows];
		const index = updatedRows.findIndex(
			(r) => r.classid === params.row.classid
		);
		updatedRows.splice(index, 1);
		setRows(updatedRows);
		const updatedPropRows = [...props.rows];
		const indexProp = updatedPropRows.findIndex(
			(r) => r.classid === params.row.classid
		);
		updatedPropRows.splice(indexProp, 1);
		props.setRows(updatedPropRows);
	};

	const renderDeleteButton = (params) => {
		if (params.row.new) {
			return (
				<div>
					<Button
						variant="contained"
						color="error"
						size="small"
						onClick={() => handleDelete(params)}
					>
						Delete
					</Button>
				</div>
			);
		} else {
			return <div></div>;
		}
	};

	const handleTeamVisibility = (row) => {
		const teamNames = row.roles.map((role) => "'" + role.name + "'");
		const teamName = teamNames.join(", ");
		const updatedRows = [...rows];
		const index = updatedRows.findIndex((r) => r.id === row.id);
		updatedRows[index].teamName = teamName;
		updatedRows[index].nameExpanded = true;
		setRows(updatedRows);
	};

	const renderInstructorName = (params) => {
		return (
			<InstructorCell
				row={params.row}
				handleTeamVisibility={props.handleTeamVisibility}
			/>
		);
	};

	/* const renderInstructorName = (params) => {
		const { row } = params;
		if (row.roles && row.roles.length === 1) {
			return (
				<strong>
					<Button>
						<Typography sx={{ marginRight: "10px" }} color="black">
							{row.name}
						</Typography>
					</Button>
				</strong>
			);
		} else if (row.roles && row.roles.length > 1 && !row.nameExpanded) {
			return (
				<strong>
					<Button onClick={() => handleTeamVisibility(row)}>
						<Typography sx={{ marginRight: "10px" }}>(Several)</Typography>
						<VisibilityIcon />
					</Button>
				</strong>
			);
		} else if (row.roles && row.roles.length > 1 && row.nameExpanded) {
			return (
				<strong>
					<Button>
						<Typography sx={{ marginRight: "10px" }} onClick={() => handleTeamVisibility(row)}>
							{row.teamName}
						</Typography>
					</Button>
				</strong>
			);
		} else {
			return <strong></strong>;
		}
	}; */

	const columns = [
		{
			field: "classid",
			headerName: "Class ID",
			width: 100,
		},
		{
			field: "ctitle",
			headerName: "Class Title",
			width: 180,
		},

		{
			field: "csize",
			headerName: "Size",
			width: 60,
		},
		{
			field: "name",
			headerName: "Instructor Name",
			width: 180,
			flex: 1,
			renderCell: renderInstructorName,
		},
		{
			field: "c_edate",
			headerName: "Class End Date",
			width: 110,
			disableClickEventBubbling: true,
		},

		{
			field: "examdate",
			headerName: "Exam Date",
			width: 110,
			disableClickEventBubbling: true,
		},

		{
			field: "c_location",
			headerName: "Location",
			width: 80,
			disableClickEventBubbling: true,
		},
		{
			field: "teamsize",
			headerName: "Team Size",
			width: 80,
			disableClickEventBubbling: true,
		},
		{
			field: "delete",
			headerName: "",
			width: 80,
			renderCell: renderDeleteButton,
		},
	];

	return (
		<div>
			<Modal
				open={props.open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Box
						sx={{
							height: "700px",
							width: "100%",
							p: 2,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<Box sx={{ display: "flex", width: "100%", height: "80%" }}>
							<DataGrid
								sx={{
									"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
										outline: "None !important",
									},
									"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
										width: "0.4em",
									},
									"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
										background: "#f1f1f1",
									},
									"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
										backgroundColor: "#888",
									},
									"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover":
										{
											background: "#555",
										},

									borderRadius: "1px 1px 4px 4px",
								}}
								disableSelectionOnClick
								rows={rows}
								density="compact"
								columns={columns}
								initialState={{
									pagination: {
										paginationModel: { pageSize: 100, page: 0 },
									},
								}}
								pageSizeOptions={[5, 10]}
								getRowId={(row) => row.classid}
							/>
						</Box>
						<div className="pt-4">
							{rows && rows.length === 9 ? (
								<Button
									variant="contained"
									color="error"
									sx={{ marginLeft: "10px" }}
								>
									Max Instances Reached
								</Button>
							) : openInstructorDialog ? (
								<div className="w-[500px] p-4 border-2 rounded-md shadow-md">
									<p className="text-center">
										Please search for and add instructors. Press "Done" when you
										have finished.
									</p>
									<ClassAdjustAddInstructor
										rows={props.rows}
										setRows={props.setRows}
										selectedClass={classForInstance}
										setAddInstructor={setOpenInstructorDialog}
										setUnsavedChanges={props.setUnsavedChanges}
										isInInstance={true}
									/>
								</div>
							) : (
								<div className="py-4">
									<Button
										variant="contained"
										onClick={handleAddInstantClassSame}
										sx={{ marginLeft: "10px" }}
									>
										Add Instanced Class With Same Instructor(s)
									</Button>
									<Button
										variant="outlined"
										onClick={handleAddInstantClassDifferent}
										sx={{ marginLeft: "10px" }}
									>
										Add Instanced Class With Different Instructor(s)
									</Button>
								</div>
							)}
							{/* {openInstructorDialog && (
								
							)} */}
						</div>
						<Box
							sx={{
								width: "100%",
								height: "20%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "end",
								alignItems: "end",
								gap: "10px",
							}}
						>
							<Button
								variant="contained"
								color="error"
								disabled={postClassIdsRolesMutation.isLoading}
								sx={{
									marginTop: "5px",
									width: "100px",
									display: "flex",
								}}
								onClick={handleClose}
							>
								Cancel
							</Button>
							{postClassIdsRolesMutation.isLoading ? (
								<Button
									variant="contained"
									sx={{
										marginTop: "5px",
										width: "100px",
										display: "flex",
									}}
								>
									<CircularProgress size={20} />
								</Button>
							) : (
								<Button
									variant="contained"
									disabled={
										props.rows?.length === 0 ||
										postClassIdsRolesMutation.isLoading ||
										openInstructorDialog ||
										listOfAddedClasses.length === 0
									}
									sx={{
										marginTop: "5px",
										width: "100px",
										display: "flex",
									}}
									onClick={handleSubmit}
								>
									Confirm
								</Button>
							)}
						</Box>
					</Box>
				</Box>
			</Modal>

			{/*Add instructor to the role, not push right away.*/}
		</div>
	);
}
