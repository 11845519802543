import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState, useEffect, useContext } from "react";
import { Typography, Tooltip } from "@mui/material";
import {
	RequisitionsContext,
	ClassesContext,
	StartDateContext,
	EndDateContext,
	TeamTeachingContext,
	SmallRequisitionContext,
	TermContext,
} from "../../../../../../context/RequisitionContext";
import dayjs from "dayjs";
import ClassesTable from "../../../../components/ClassesTable";
import AddClassesModal from "../Modals/AddClassesModal";
import ItemsTable from "../../../../components/ItemsTable";
import { useAllItemsQuery } from "../../../../../../Hooks/useAllItemsQuery";
import isAuthenticated from "../../../../../../components/Utils/isAuthenticated";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90vw",
	height: "95vh",
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	p: 2,
	display: "flex",
	flexDirection: "column",
};

export default function ViewReqModal(props) {
	const [termValue, setTermValue] = useState("");

	const auth = isAuthenticated();
	const token = auth.token;
	const { requisitions } = useContext(RequisitionsContext);

	const [itemListStringDisplay, setItemListStringDisplay] = useState("");
	const [teamTeachingAgree, setTeamTeachingAgree] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [chosenClasses, setChosenClasses] = useState([]);
	const [numOfTTItems, setNumOfTTItems] = useState(0);
	const [teamTeachingItemsStringDisplay, setTeamTeachingItemsStringDisplay] =
		useState("");
	const [smallRequisition, setSmallRequisition] = useState({
		classes: false,
		items: false,
	});
	const [openAddClassesModal, setOpenAddClassesModal] = useState(false);
	const [chosenItems, setChosenItems] = useState([]);
	const [modifiedItems, setModifiedItems] = useState(false);
	const [modifiedClasses, setModifiedClasses] = useState(false);
	const [cancelledModifyItems, setCancelledModifyItems] = useState(false);
	const [sortedChosenItems, setSortedChosenItems] = useState([]);

	const { data: allItemsData, status: allItemsStatus } = useAllItemsQuery(
		token,
		{
			enabled: !props.userInteracting,
		}
	);

	const [termValueList, setTermValueList] = useState([]);

	useEffect(() => {
		if (props.termValue && props.termValue.length > 0) {
			const newTermValue = props.termValue
				.split(" ")[2]
				.replace("(", "")
				.replace(")", "");
			setTermValue(newTermValue);
		}
	}, [props.termValue]);

	useEffect(() => {
		if (props.terms && props.terms.length > 0) {
			let termValueListCopy = [];
			props.terms.forEach((term) => {
				if (term && term !== undefined && term.length > 0) {
					const termParts = term.split(" ");
					if (termParts.length > 2) {
						const newTermValue = termParts[2].replace("(", "").replace(")", "");
						termValueListCopy.push(newTermValue);
					}
				}
			});
			setTermValueList(termValueListCopy);
		}
	}, [props.terms]);

	const [itemList, setItemList] = useState([]);

	useEffect(() => {
		if (
			props.rowData &&
			allItemsStatus === "success" &&
			props.rowData.itemList
		) {
			if (
				props.rowData.itemList.some(
					(item) => item.itemno >= 1001 && item.itemno <= 1999
				)
			) {
				const newList = props.rowData.itemList.map((item) => {
					if (item.itemno >= 1001 && item.itemno <= 1999) {
						const itemIndex = allItemsData.findIndex(
							(i) => i.itemno === item.itemno && i.reqnum === item.reqnum
						);
						return itemIndex !== -1
							? { ...item, ...allItemsData[itemIndex] }
							: item;
					} else {
						const itemIndex = allItemsData.findIndex(
							(i) => i.itemno === item.itemno
						);
						return itemIndex !== -1
							? { ...item, ...allItemsData[itemIndex] }
							: item;
					}
				});
				setItemList(newList);
			} else {
				const newList = props.rowData.itemList.map((item) => {
					const itemIndex = allItemsData.findIndex(
						(i) => i.itemno === item.itemno
					);
					return itemIndex !== -1
						? { ...item, ...allItemsData[itemIndex] }
						: item;
				});
				setItemList(newList);
			}
		}
	}, [props.rowData, allItemsStatus, allItemsData]);

	useEffect(() => {
		const itemListSortedByQOrder = Array.isArray(itemList) ? [...itemList] : [];
		itemListSortedByQOrder.sort((a, b) => {
			return a.q_order > b.q_order ? 1 : -1;
		});

		setChosenItems(itemListSortedByQOrder);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.rowData, allItemsData, allItemsStatus, itemList]);

	useEffect(() => {
		//if all items are type OPEN, then it is a small requisition
		if (props.rowData && props.rowData.itemList) {
			if (props.rowData.itemList.length > 0) {
				const smallRequisitionCopy = { ...smallRequisition };
				if (
					props.rowData.itemList.every(
						(chosenItem) => chosenItem.itmtyp === "OPEN"
					)
				) {
					smallRequisitionCopy.items = true;
				} else {
					smallRequisitionCopy.items = false;
				}
				setSmallRequisition(smallRequisitionCopy);
			}
		}
	}, [props.rowData]);

	useEffect(() => {
		//if no classes have more than 10 students, then it is smallRequisition.classes = true
		if (chosenClasses.length > 0) {
			const smallRequisitionCopy = { ...smallRequisition };
			if (chosenClasses.every((chosenClass) => chosenClass.csize < 10)) {
				smallRequisitionCopy.classes = true;
			} else {
				smallRequisitionCopy.classes = false;
			}
			setSmallRequisition(smallRequisitionCopy);
		}
	}, [chosenClasses]);

	useEffect(() => {
		if (props.rowData && props.rowData.classes) {
			const smallRequisitionCopy = { ...smallRequisition };
			//if any classes have a csize less than 10, then smallRequisition.classes is true
			if (props.rowData.classes.length > 0) {
				if (props.rowData.classes.every((c) => c.csize < 10)) {
					smallRequisitionCopy.classes = true;
				} else {
					smallRequisitionCopy.classes = false;
				}
				setSmallRequisition(smallRequisitionCopy);
			}
		}
	}, [props.rowData]);

	useEffect(() => {
		if (requisitions.length > 0) {
			requisitions.forEach((req) => {
				if (req.reqnum === props.rowData.reqnum) {
					setStartDate(req.start_date);
					setEndDate(req.end_date);
					req.classes.forEach((c) => {
						c.id = c.cls_uqid;
					});
					setChosenClasses(req.classes);
				}
			});
		}
	}, [props.rowData, requisitions]);

	useEffect(() => {
		let tempItemListString = "";
		if (props.rowData.itemList) {
			requisitions.forEach((req) => {
				if (req.reqnum === props.rowData.reqnum) {
					if (req.itemList && req.itemList.length > 0) {
						req.itemList.forEach((item) => {
							tempItemListString += item.itemno + ",";
						});
					}
				}
				tempItemListString = tempItemListString.slice(0, -1);
				req.itemListString = tempItemListString;
			});
		}
	}, [requisitions, props.rowData]);

	useEffect(() => {
		if (requisitions.length > 0) {
			requisitions.forEach((req) => {
				if (req.reqnum === props.rowData.reqnum) {
					setItemListStringDisplay(req.itemListString);
				}
			});
		}
	}, [props.rowData, requisitions]);

	useEffect(() => {
		if (requisitions.length > 0) {
			requisitions.forEach((req) => {
				if (req.reqnum === props.rowData.reqnum) {
					if (req.classes && req.classes.length > 0) {
						//if there are 2 or more of the same classids, then team teaching is true
						const classIds = req.classes.map((c) => c.classid);
						const uniqueClassIds = [...new Set(classIds)];
						if (classIds.length !== uniqueClassIds.length) {
							setTeamTeachingAgree(true);
						} else {
							setTeamTeachingAgree(false);
						}
					}
				}
			});
		}
	}, [props.rowData, requisitions]);

	useEffect(() => {
		const numberTeamTeachingItems = () => {
			let count = 0;
			let ttItemArray = [];
			if (requisitions.length > 0) {
				requisitions.forEach((req) => {
					if (req.reqnum === props.rowData.reqnum) {
						if (req.itemList && req.itemList.length > 0) {
							req.itemList.forEach((item) => {
								if (
									item.repeat === "Y" ||
									item.repeat === true ||
									item.repeat === "Yes"
								) {
									ttItemArray.push(item);
									count++;
								}
							});
						}
					}
				});
			}
			setNumOfTTItems(count);
			setTeamTeachingItemsStringDisplay(
				ttItemArray.map((item) => item.itemno).join(", ")
			);
		};

		numberTeamTeachingItems(); // Call the function inside the useEffect
	}, [props.rowData.reqnum, requisitions]);

	useEffect(() => {
		// Compare itemList and chosenItems
		const isModified = JSON.stringify(itemList) !== JSON.stringify(chosenItems);
		setModifiedItems(isModified);
	}, [props.rowData, chosenItems]);

	useEffect(() => {
		if (cancelledModifyItems) {
			setChosenItems(itemList);
			setCancelledModifyItems(false);
		}
	}, [cancelledModifyItems, itemList]);

	const handleClose = (event, reason) => {
		if (reason && reason === "backdropClick") return;
		props.setOpen(false);
	};

	return (
		<div>
			<ClassesContext.Provider value={{ chosenClasses, setChosenClasses }}>
				<SmallRequisitionContext.Provider
					value={{ smallRequisition, setSmallRequisition }}
				>
					<StartDateContext.Provider value={startDate}>
						<EndDateContext.Provider value={endDate}>
							<TeamTeachingContext.Provider value={teamTeachingAgree}>
								<Modal
									open={props.open}
									onClose={handleClose}
									aria-labelledby="modal-modal-title"
									aria-describedby="modal-modal-description"
								>
									<Box sx={style}>
										<Box
											sx={{
												/*  outline: "1px solid red", */ display: "flex",
												flexDirection: "column",
												height: "95%",
											}}
										>
											<Typography
												fontSize={16}
												fontWeight={600}
												textAlign={"center"}
											>
												View/Edit Requisition
											</Typography>
											<Box
												sx={{
													outline: "2px solid #E0E0E0",
													borderRadius: "5px",
													display: "flex",
													padding: "4px",
													justifyContent: "space-between",
													height: "30%",
												}}
											>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														width: "65%" /* outline: "1px solid yellow" */,
													}}
												>
													<Box sx={{ display: "flex", gap: "3px" }}>
														<Typography
															fontSize={16}
															fontWeight={600}
														>
															Requisition:
														</Typography>
														<Typography fontSize={16}>
															{props.rowData.reqnum}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", gap: "3px" }}>
														<Typography
															fontSize={16}
															fontWeight={600}
														>
															Date Created:
														</Typography>
														<Typography fontSize={16}>
															{props.rowData.rqdate}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", gap: "3px" }}>
														<Typography
															fontSize={16}
															fontWeight={600}
														>
															Block IDs:
														</Typography>
														<Typography fontSize={16}>
															{props.rowData.blkid1}, {props.rowData.blkid2},{" "}
															{props.rowData.blkid3}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", gap: "3px" }}>
														<Typography
															fontSize={16}
															fontWeight={600}
														>
															Total Numerical Questions:
														</Typography>
														<Typography fontSize={16}>
															{props.rowData.totrtg}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", gap: "3px" }}>
														<Typography
															fontSize={16}
															fontWeight={600}
														>
															Total Open-Ended Questions:
														</Typography>
														<Typography fontSize={16}>
															{props.rowData.totopn}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", gap: "3px" }}>
														<Typography
															fontSize={16}
															fontWeight={600}
														>
															Items:
														</Typography>
														{itemListStringDisplay.length > 56 && (
															<Typography fontSize={16}>
																{itemListStringDisplay.slice(
																	0,
																	itemListStringDisplay.indexOf(",", 56) + 1
																)}
																{"\n"}
																{itemListStringDisplay.slice(
																	itemListStringDisplay.indexOf(",", 56) + 1
																)}
															</Typography>
														)}
														{itemListStringDisplay.length <= 56 && (
															<Typography fontSize={16}>
																{itemListStringDisplay}
															</Typography>
														)}
													</Box>
													<Box
														sx={{
															display: "flex",
															gap: "3px",
															marginBottom: "20px",
														}}
													>
														<Typography
															fontSize={16}
															fontWeight={600}
														>
															Team Teaching Items:
														</Typography>
														<Typography fontSize={16}>
															({numOfTTItems}): {teamTeachingItemsStringDisplay}
														</Typography>
													</Box>
												</Box>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														width: "35%",
														/* outline: "1px solid purple", */
													}}
												>
													<Box sx={{ display: "flex", gap: "3px" }}>
														<Typography
															fontSize={16}
															fontWeight={600}
														>
															Requested By:
														</Typography>
														<Typography fontSize={16}>
															{props.rowData.raddr1}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", gap: "3px" }}>
														<Typography
															fontSize={16}
															fontWeight={600}
														>
															Department:
														</Typography>
														<Typography fontSize={16}>
															{props.rowData.raddr2}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", gap: "3px" }}>
														<Typography
															fontSize={16}
															fontWeight={600}
														>
															Start Date:
														</Typography>
														<Typography fontSize={16}>
															{dayjs(props.rowData.start_date).format(
																"DD/MM/YY"
															)}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", gap: "3px" }}>
														<Typography
															fontSize={16}
															fontWeight={600}
														>
															End Date:
														</Typography>
														<Typography fontSize={16}>
															{dayjs(props.rowData.end_date).format("DD/MM/YY")}
														</Typography>
													</Box>
												</Box>
											</Box>
											{props.rowData.classes_released ? (
												<Box
													sx={{
														display: "flex",
														flexDirection: "row",
														gap: "10px",
														margin: "5px",
														marginLeft: "0px",
													}}
												>
													<Tooltip
														arrow
														title="Cannot modify items if a class in this requisition has been released."
														placement="top"
													>
														<span>
															<Button
																variant="contained"
																onClick={() =>
																	props.setOpenEditItems(!props.openEditItems)
																}
															>
																View Questions
															</Button>
														</span>
													</Tooltip>
													<Button
														variant="contained"
														disabled={
															props.openEditItems || props.openEditClasses
														}
														onClick={() =>
															props.setOpenEditClasses(!props.openEditClasses)
														}
													>
														Edit Classes
													</Button>
												</Box>
											) : (
												<Box
													sx={{
														display: "flex",
														flexDirection: "row",
														gap: "10px",
														margin: "5px",
														marginLeft: "0px",
													}}
												>
													<Button
														variant="contained"
														disabled={
															props.openEditClasses || props.openEditItems
														}
														onClick={() =>
															props.setOpenEditItems(!props.openEditItems)
														}
													>
														View Questions
													</Button>
													<Button
														variant="contained"
														disabled={
															props.openEditItems || props.openEditClasses
														}
														onClick={() =>
															props.setOpenEditClasses(!props.openEditClasses)
														}
													>
														Edit Classes
													</Button>
												</Box>
											)}

											{props.openEditItems && (
												<Box
													sx={{
														height: "60%" /* outline: "2px solid brown" */,
													}}
												>
													<ItemsTable
														inModify
														inModifyFirst
														termNumber={termValue}
														chosenItems={chosenItems}
														setChosenItems={setChosenItems}
														modifiedItems={modifiedItems}
														setModifiedItems={setModifiedItems}
														rowData={props.rowData}
														setOpenEditItems={props.setOpenEditItems}
														setCancelledModifyItems={setCancelledModifyItems}
														sortedChosenItems={sortedChosenItems}
														setSortedChosenItems={setSortedChosenItems}
													/>
												</Box>
											)}
											{props.openEditClasses && (
												<Box
													sx={{
														height: "60%" /* outline: "2px solid brown" */,
													}}
												>
													<ClassesTable
														rowData={props.rowData}
														setRowData={props.setRowData}
														setOpenEditClasses={props.setOpenEditClasses}
														setOpenAddClassesModal={setOpenAddClassesModal}
														deptId={props.deptId}
														setDeptId={props.setDeptId}
														startDate={startDate}
														endDate={endDate}
														setModifiedClasses={setModifiedClasses}
														modifiedClasses={modifiedClasses}
														inModify
														inModifyFirst
													/>
													<AddClassesModal
														open={openAddClassesModal}
														setOpen={setOpenAddClassesModal}
														deptId={props.deptId}
														setDeptId={props.setDeptId}
														rowData={props.rowData}
														termsList={props.termsList}
														terms={termValueList}
														termValue={termValue}
														setTermValue={setTermValue}
														setModifiedClasses={setModifiedClasses}
													/>
												</Box>
											)}
										</Box>
										<Button
											variant="contained"
											disabled={props.openEditItems || props.openEditClasses}
											onClick={handleClose}
											sx={{
												position: "absolute",
												bottom: "5px",
												height: "40px",
												width: "400px",
												alignSelf: "center",
											}}
										>
											Done
										</Button>
									</Box>
								</Modal>
							</TeamTeachingContext.Provider>
						</EndDateContext.Provider>
					</StartDateContext.Provider>
				</SmallRequisitionContext.Provider>
			</ClassesContext.Provider>
		</div>
	);
}
