import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, Checkbox, Tooltip } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { darken, lighten } from "@mui/material/styles";
import { checkboxClasses } from "@mui/material/Checkbox";
import DeleteForever from "@mui/icons-material/DeleteForever";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import UpdateIcon from "@mui/icons-material/Update";
import PreviewIcon from "@mui/icons-material/Preview";
import PublishIcon from "@mui/icons-material/Publish";
import CancelIcon from "@mui/icons-material/Cancel";
// Styled DataGrid
const getBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);
const getHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
const getSelectedBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
const getSelectedHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	"& .super-app-theme--noEvaluation": {
		backgroundColor: getBackgroundColor(
			theme.palette.george.main,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.george.main,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.george.main,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.george.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--evaluation": {
		backgroundColor: getBackgroundColor(
			theme.palette.success.main,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.success.main,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.success.main,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.success.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--Mt": {
		backgroundColor: getBackgroundColor(
			theme.palette.mitra.main,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.mitra.main,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.mitra.main,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.mitra.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--J": {
		backgroundColor: getBackgroundColor(
			theme.palette.info.main,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.info.main,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.info.main,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.info.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .MuiDataGrid-overlay": {
		backgroundColor: "rgba(255, 255, 255, 0.7) !important",
	},
}));

const DataGridTable = ({
	rows,
	setRows,
	columns,
	loading,
	paginationModel,
	setPaginationModel,
	handleSelectionModelChange,
	getRowClassName,
	filterKey,
	unsavedChanges,
	reviewingClasses,
	combiningMode,
	customCheckboxSelection,
	isDragging,
	handleMouseDown,
	handleMouseEnter,
	handleClickAddClassCheckbox,
	handleSubmitAllSuitable,
	confirmSubmitAllSelected,
	handleReviewAllSelected,
	submitAllLoading,
	handleSubmitAllSelected,
	handleCancelSubmitAllSelected,
	batchUpdated,
	openBatchUpdateDialog,
	setOpenBatchUpdateDialog,
	setConfirmSubmitAllSelected,
	handleCancelSubmitAllSuitable,
	handleConfirmSubmitAllSuitable,
	renderInstructorNameTable,
	hasNoBlockIdCheck,
	hasNoRolesCheck,
	isClassSizeTooSmallCheck,
	isClassSizeZeroCheck,
	handleClick,
	validateClass,
	dateFormat,
	setRowsForTableOriginal,
	setUnsavedChanges,
	setSelectedClass,
	selectedClass,
	setCheckboxSelectionState,
	checkboxSelectionState,
	...props
}) => {
	const handleChangeBlkId = (event, params) => {
		const value = event.target.value;

		const classId = rows.find((row) => row.classid === params.row.classid);
		const updatedClass = { ...classId, blkid: value, form: value };

		const updatedRows = rows.map((row) =>
			row.classid === params.row.classid ? updatedClass : row
		);
		setRows(updatedRows);

		setRowsForTableOriginal(updatedRows);
		if (params.row.evaluate === "No" || params.row.evaluate === "Yes") {
			setUnsavedChanges(true);
		}
	};
	const columnsRequisitions = [
		{
			field: "classid",
			headerName: "Class ID",
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Class ID</p>
					</div>
				);
			},
			renderCell: (params) => {
				return (
					<div>
						<p className="text-sm text-black font-semibold">
							{params.row.classid}
						</p>
					</div>
				);
			},
			width: 60,
		},
		{
			field: "ctitle",
			headerName: "Class Title",
			type: "string",
			flex: 1,
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Class Title</p>
					</div>
				);
			},
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">
							{params.row.ctitle}
						</p>
					</div>
				);
			},
			sortComparator: (v1, v2) => {
				// Helper function to split the ctitle into parts
				const parseCtitle = (title) => {
					const parts = title.split(" ");
					if (parts.length === 5) {
						// For 5-part titles, merge the first two parts to treat as SUBJECT
						return [parts[0] + " " + parts[1], parts[2], parts[3], parts[4]];
					}
					return parts;
				};

				const parts1 = parseCtitle(v1);
				const parts2 = parseCtitle(v2);

				// Compare each part in order
				for (let i = 0; i < parts1.length; i++) {
					if (parts1[i] < parts2[i]) return -1;
					if (parts1[i] > parts2[i]) return 1;
				}

				// If all parts are equal
				return 0;
			},
			width: 150,
		},
		{
			field: "csize",
			editable: true,
			headerName: "# Std",
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold"># Std</p>
					</div>
				);
			},
			align: "center",
			width: 55,
		},

		{
			field: "blkid",
			headerName: "Block",
			width: 90,
			renderCell: (params) => {
				return (
					<p className="text-xs text-black font-semibold">
						{params.row.blkid ? params.row.blkid : ""}
					</p>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Block</p>
					</div>
				);
			},
			align: "center",

			disableClickEventBubbling: true,
		},
		{
			field: "name",
			headerName: "Instructor(s)",
			width: 150,
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Instructor(s)</p>
					</div>
				);
			},
			renderCell: renderInstructorNameTable,
		},
		{
			field: "evaluate",
			headerName: "Evaluate",
			width: 60,
			disableSelectionOnClick: true,
			disableClickEventBubbling: true,
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Evaluate</p>
					</div>
				);
			},
			align: "center",
			sortComparator: (v1, v2) => {
				const order = ["Yes", "Pending", "No", "Caution", null, ""];

				const index1 = order.indexOf(v1);
				const index2 = order.indexOf(v2);

				if (index1 < index2) {
					return -1;
				}
				if (index1 > index2) {
					return 1;
				}
				return 0;
			},
			renderCell: (params) => {
				if (params.row.evaluate === "Yes") {
					return (
						<Checkbox
							disabled={unsavedChanges || combiningMode}
							onClick={(event) => handleClick(event, params.row, "evaluated")}
							checked={params.row.evaluate === "Yes" ? true : false}
						/>
					);
				} else if (params.row.evaluate === "Pending") {
					return (
						<Tooltip
							title="Pending. Please submit the currently selected courses."
							placement="right"
						>
							<Checkbox
								sx={{ color: "#FFB600", "&.Mui-checked": { color: "#FFB600" } }}
								//confirm here as the class will be deleted from AdjustedClassIds
								onClick={(event) => handleClick(event, params.row, "pending")}
								checked={params.row.evaluate === "Pending" ? true : false}
							/>
						</Tooltip>
					);
				} else if (params.row.evaluate === "No") {
					return (
						<Checkbox
							disabled={
								unsavedChanges ||
								(params.row.combinedWith && params.row.combinedWith.length > 0)
							}
							sx={{
								[`&, &.${checkboxClasses.checked}`]: {
									color: unsavedChanges ? "gray" : "red",
								},
							}}
							checkedIcon={<DisabledByDefaultIcon />}
							onClick={(event) => handleClick(event, params.row, "excluded")}
							checked={params.row.evaluate === "No" ? true : false}
						/>
					);
				} else {
					if (
						hasNoRolesCheck(params.row) ||
						hasNoBlockIdCheck(params.row) ||
						isClassSizeTooSmallCheck(params.row) ||
						isClassSizeZeroCheck(params.row)
					) {
						return (
							<Tooltip
								placement="right"
								title={validateClass(params.row).message}
							>
								<WarningIcon sx={{ color: "#FFB600" }} />
							</Tooltip>
						);
					} else {
						return (
							<div onClick={handleClickAddClassCheckbox}>
								<Checkbox
									// onMouseDown={(event) =>
									// 	handleMouseDown(event, params.row.classid)
									// }
									// onMouseEnter={(event) =>
									// 	handleMouseEnter(event, params.row.classid)
									// }
									disabled={
										(unsavedChanges && customCheckboxSelection.length === 0) ||
										combiningMode ||
										!params.row.blkid ||
										(params.row.roles && params.row.roles.length === 0) ||
										params.row.csize === 0
									}
									onClick={(event) => handleClick(event, params.row, "neither")}
									checked={params.row.evaluate === "No" ? true : false}
								/>
							</div>
						);
					}
				}
			},
		},

		{
			field: "c_edate",
			headerName: "Class End Date",
			width: 80,
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">
							{params.row.c_edate &&
								dayjs(params.row.c_edate).format(dateFormat)}
						</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">End Date</p>
					</div>
				);
			},
			headerAlign: "center",
			disableClickEventBubbling: true,
			align: "center",
		},

		{
			field: "examdate",
			headerName: "Exam Date",
			align: "center",
			headerAlign: "center",
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">
							{params.row.examdate &&
								dayjs(params.row.examdate).format(dateFormat)}
						</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Exam Date</p>
					</div>
				);
			},
			width: 80,
			disableClickEventBubbling: true,
		},

		{
			field: "survey_start",
			headerName: "Survey Start",
			align: "center",
			headerAlign: "center",
			width: 80,
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">
							{params.row.survey_start &&
								dayjs(params.row.survey_start).format(dateFormat)}
						</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold mr-2">
							Survey Start
						</p>
					</div>
				);
			},
			disableClickEventBubbling: true,
		},

		{
			field: "survey_end",
			headerName: "Survey End",
			align: "center",
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">
							{params.row.survey_end &&
								dayjs(params.row.survey_end).format(dateFormat)}
						</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold mr-2">Survey End</p>
					</div>
				);
			},
			width: 80,
			disableClickEventBubbling: true,
		},
	];

	const slots = {
		LoadingOverlay: LinearProgress,
		Toolbar: GridToolbar,
	};
	return (
		<div className="flex flex-col h-full w-full">
			<StyledDataGrid
				key={filterKey}
				loading={loading}
				disabled={unsavedChanges}
				sx={{
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "None !important",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
						width: "0.4em",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
						background: "#f1f1f1",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
						backgroundColor: "#888",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
						background: "#555",
					},
					"& .MuiDataGrid-row:hover": {
						cursor: props.unsavedChanges ? "default" : "pointer",
						backgroundColor: props.unsavedChanges && "inherit", // Or 'transparent' or whatever color you'd like
					},
					borderRadius: "0px 4px 4px 0px",
				}}
				className="requisitions-table"
				rows={rows}
				columns={columnsRequisitions}
				pagination
				disableSelectionOnClick={unsavedChanges || reviewingClasses}
				checkboxSelection={combiningMode}
				initialState={
					!reviewingClasses && {
						sorting: {
							sortModel: [{ field: "ctitle", sort: "asc" }],
						},
					}
				}
				onSelectionModelChange={handleSelectionModelChange}
				getRowClassName={getRowClassName}
				components={slots}
				density="compact"
				getRowId={(row) => row.classid}
			/>

			{reviewingClasses ? (
				<div className="flex gap-2">
					<Button
						disabled={props.unsavedChanges}
						onClick={handleCancelSubmitAllSuitable}
						variant="contained"
						color="error"
						className="w-full h-10  text-white font-semibold rounded-br-md"
					>
						Cancel
					</Button>
					<Button
						disabled={props.unsavedChanges}
						onClick={handleConfirmSubmitAllSuitable}
						variant="contained"
						className="w-full h-10 bg-green-800 hover:bg-green-900 text-white font-semibold rounded-br-md"
					>
						{submitAllLoading ? (
							<div className="flex justify-center gap-2">
								<p>Please Wait</p>{" "}
								<CircularProgress
									size={20}
									sx={{ color: "white" }}
								/>
							</div>
						) : (
							"Confirm Submission"
						)}
					</Button>
				</div>
			) : customCheckboxSelection.length > 0 ? (
				confirmSubmitAllSelected ? (
					<div
						className="flex gap-2"
						onClick={handleClickAddClassCheckbox}
					>
						<Button
							onClick={handleCancelSubmitAllSelected}
							variant="contained"
							color="error"
							className="w-full h-10  text-white font-semibold rounded-br-md"
						>
							<CancelIcon /> Cancel
						</Button>
						<Button
							onClick={handleSubmitAllSelected}
							variant="contained"
							className="w-full h-10 bg-green-800 hover:bg-green-900 text-white font-semibold rounded-br-md"
						>
							{submitAllLoading ? (
								<div className="flex justify-center gap-2">
									<p>Please Wait</p>{" "}
									<CircularProgress
										size={20}
										sx={{ color: "white" }}
									/>
								</div>
							) : (
								<>
									<PublishIcon />
									Submit Selected Courses
								</>
							)}
						</Button>
					</div>
				) : (
					<div
						onClick={handleClickAddClassCheckbox}
						className="flex "
					>
						<Button
							onClick={handleCancelSubmitAllSelected}
							variant="outlined"
							color="error"
							sx={{ borderRadius: "0px", color: "red" }}
							className="w-full h-10  text-white font-semibold "
						>
							<DeleteForever />
							Clear Selection
						</Button>
						<Button
							className="w-full h-10  text-white font-semibold "
							color="info"
							variant="contained"
							sx={{ borderRadius: "0px" }}
							onClick={() => setOpenBatchUpdateDialog(true)}
						>
							<EditIcon />
							<QuestionAnswerIcon />
							<CalendarMonthIcon />
							Edit ({customCheckboxSelection.length}) Courses
							{/* <QuestionAnswerIcon />
							<CalendarMonthIcon /> */}
						</Button>
						<Button
							onClick={handleReviewAllSelected}
							variant="contained"
							sx={{ borderRadius: "0px" }}
							className="w-full h-10 text-white font-semibold"
						>
							<PreviewIcon />
							Review/Submit ({customCheckboxSelection.length}) Courses
						</Button>
					</div>
				)
			) : (
				<div onClick={handleClickAddClassCheckbox}>
					<Button
						disabled={props.unsavedChanges || combiningMode}
						onClick={handleSubmitAllSuitable}
						variant="contained"
						sx={{ borderRadius: "0px 0px 5px 0px" }}
						className="w-full h-10 bg-green-800 hover:bg-green-900 text-white font-semibold "
					>
						<PublishIcon />
						Submit Whole Department
					</Button>
				</div>
			)}
		</div>
	);
};

export default DataGridTable;
