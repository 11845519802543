import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { GridFooterContainer, GridFooter, DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useContext, useState, useEffect } from "react";
import {
	ClassesContext,
	ClassIdsContext,
	TeamTeachingContext,
	RequisitionsContext,
} from "../../../context/RequisitionContext";
import isAuthenticated from "../../../components/Utils/isAuthenticated";
import { deleteClasses, deleteAdjustedClassId } from "../../../components/Api/Deletes";
import { updateClasses, updateBlueCourseDates } from "../../../components/Api/Updates";
import { postRequisitionBlue } from "../../../components/Api/Post";
import { useMutation, useQueryClient } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { RequisitionCalendar } from "../../../components/Utils/RequisitionCalendar";
import DeleteForever from "@mui/icons-material/DeleteForever";

var utc = require("dayjs/plugin/utc");

var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

export default function ClassesTable(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const access = auth.access;
	const ccid = auth.ccid;
	const { chosenClasses, setChosenClasses } = useContext(ClassesContext);
	const { classIds, setClassIds } = useContext(ClassIdsContext);
	const teamTeachingAgree = useContext(TeamTeachingContext);
	const context = useContext(RequisitionsContext);
	const requisitions = context?.requisitions || [];
	const [, setMouseDown] = useState(false);
	const [longPressTimer, setLongPressTimer] = useState(null);
	const queryClient = useQueryClient();

	const [changesLoading, setChangesLoading] = useState(false);
	const [termNumber, setTermNumber] = useState("");
	const [deletedClassIds, setDeletedClassIds] = useState([]);
	const massDelete = () => {
		const updatedChosenClasses = [...chosenClasses];
		updatedChosenClasses.splice(0, updatedChosenClasses.length);
		setChosenClasses(updatedChosenClasses);
	};
	const blueRequisitionsMutation = useMutation((requisition, ccid) =>
		postRequisitionBlue(token, requisition, ccid)
	);

	const postReqToBlue = (reqObjBlue) => {
		blueRequisitionsMutation.mutate(reqObjBlue, ccid, {
			onSuccess: () => {
				console.log("Success in requisitionsMutation");
			},
			onError: (error) => {
				console.error("Error in requisitionsMutation:", error);
			},
		});
	};

	const handleMouseDown = (event) => {
		event.preventDefault();
		setMouseDown(true);
		setLongPressTimer(
			setTimeout(() => {
				massDelete();
				setLongPressTimer(null);
			}, 500) // Change the duration as per your requirement (in milliseconds)
		);
	};

	const handleMouseUp = (event) => {
		event.preventDefault();
		clearTimeout(longPressTimer);
		setLongPressTimer(null);

		setMouseDown(false);
	};
	const handleAddClass = () => {
		if (props.inModifyFirst) {
			props.setOpenAddClassesModal(true);
		}
	};

	const deleteClassesMutation = useMutation((reqnum) => deleteClasses(token, reqnum));

	const postClassesMutation = useMutation((classesArray) => updateClasses(token, classesArray));

	const deleteFromBlueMutation = useMutation((classid) =>
		deleteAdjustedClassId({ token, classid })
	);

	const deleteFromBlueClasses = (classid) => {
		setChangesLoading(true);
		deleteFromBlueMutation.mutate(classid, {
			onSettled: (data, error) => {
				console.log("delete from blue classes", data);
			},
		});
	};

	const deleteClassesAndPostClasses = (reqnum, classesArray) => {
		setChangesLoading(true);

		postClassesMutation.mutate(classesArray, {
			onSettled: (data, error) => {
				if (!error) {
					setChangesLoading(false);
					/* const rowDataCopy = { ...props.rowData };
					rowDataCopy.classes = classesArray;
					props.setRowData(rowDataCopy); */
					props.setModifiedClasses(false);
					props.setOpenEditClasses(false);
					queryClient.invalidateQueries(["requisitions", token, ccid, termNumber]);
				} else {
					console.error("post classes failed", error);
					setChangesLoading(false);
				}
			},
		});
	};

	const replaceDatabaseClasses = (requisition) => {
		if (teamTeachingAgree) {
			let classids = chosenClasses.map((classItem) => classItem.classid);
			let uniqueClassids = [...new Set(classids)];
			let length = uniqueClassids.length;
			let clsnumArray = Array.from({ length: length }, (v, i) => (i + 1) * 10 + 1);
			let classidToIndex = {};
			for (let i = 0; i < length; i++) {
				classidToIndex[uniqueClassids[i]] = i;
			}
			let currentIndices = {};
			for (let i = 0; i < length; i++) {
				currentIndices[uniqueClassids[i]] = 0;
			}
			chosenClasses.forEach((classItem) => {
				let index = classidToIndex[classItem.classid];
				let clsnumOffset = clsnumArray[index] + currentIndices[classItem.classid];
				currentIndices[classItem.classid] += 1;
				classItem.clsnum = clsnumOffset;
			});
		}

		let newClass;
		let classesArray = [];
		let term = "";
		chosenClasses.forEach((classItem, index) => {
			term = classItem.term;
			let csize = classItem.csize;
			let gcSize =
				csize >= 1 && csize <= 15
					? 1
					: csize >= 16 && csize <= 35
					? 2
					: csize >= 36 && csize <= 100
					? 3
					: csize >= 100
					? 4
					: 0;
			let clevel = classItem.cnumber ? Number(classItem.cnumber.charAt(0)) : classItem.clevel;
			if (typeof classItem.comb_classes === "string") {
				if (classItem.comb_classes.length > 5) {
					classItem.comb_classes = classItem.comb_classes.split(",");
				} else {
					let temp = classItem.comb_classes;
					classItem.comb_classes = [];
					classItem.comb_classes.push(temp);
				}
			}
			newClass = {
				acyear: classItem.acyear,
				classid: classItem.classid,
				clevel: clevel, //need to adjust for combined courses
				clsnum: teamTeachingAgree ? classItem.clsnum : index + 1,
				comb_classes: classItem.comb_classes ? classItem.comb_classes.join(",") : "",
				csize: classItem.csize,
				ctitle: classItem.ctitle,
				dept_id: classItem.dept ? classItem.dept : classItem.dept_id,
				empid: classItem.empid,
				examdate: classItem.examdate,
				faculty: classItem.faculty,
				gcsize: gcSize,
				name: classItem.name,
				on_line: "Yes",
				original: null,
				qtally: null,
				rank: classItem.rank,
				reqnum: requisition.reqnum,
				status: "none",
				survey_end: dayjs(classItem.survey_end).format("YYYY-MM-DD"),
				survey_start: dayjs(classItem.survey_start).format("YYYY-MM-DD"),
				term: classItem.term,
				description: classItem.description,
				ctype: classItem.ctype,
			};
			classesArray.push(newClass);
		});

		setTermNumber(term);

		let reqnum = requisition.reqnum;

		deleteClassesAndPostClasses(reqnum, classesArray);
	};

	const confirmChanges = async () => {
		const updatedRequisitions = [...requisitions];
		const index = updatedRequisitions.findIndex((r) => r.reqnum === props.rowData.reqnum);
		if (deletedClassIds.length > 0) {
			deletedClassIds.forEach((classid) => {
				deleteFromBlueClasses(classid);
			});
		}
		if (chosenClasses.some((classItem) => classItem.roles && classItem.roles.length > 0)) {
			const filteredChosenClasses = chosenClasses.filter(
				(classItem) => classItem.roles && classItem.roles.length > 0
			);
			const updatedFilteredChosenClasses = filteredChosenClasses.map((classItem) => {
				return {
					...classItem,
					survey_start: dayjs(classItem.survey_start).format("YYYY-MM-DD"),
					survey_end: dayjs(classItem.survey_end).format("YYYY-MM-DD"),
				};
			});

			const reqObj = {
				requisition: props.rowData,
				classArray: updatedFilteredChosenClasses,
			};
			postReqToBlue(reqObj);
		}
		if (blueChanges && Object.keys(blueChanges).length > 0) {
			const blueChangesArray = Object.values(blueChanges);
			//console.log("blueChangesArray", blueChangesArray);
			updateBlueCourseDates(token, blueChangesArray);
		}
		replaceDatabaseClasses(updatedRequisitions[index]);
	};

	const cancelChanges = () => {
		props.setOpenEditClasses(false);
	};

	const renderDeleteButton = (params) => {
		return (
			<strong>
				<Button
					variant="standard"
					sx={{ width: "50px", color: "red" }}
					color="error"
					onClick={() => handleDeleteButton(params.row)}
				>
					<DeleteForever />
				</Button>
			</strong>
		);
	};

	const handleDeleteButton = (row) => {
		let updatedChosenClasses = [...chosenClasses];
		let updatedChosenClassIds = [...classIds];

		if (teamTeachingAgree === true) {
			if (row.combined === true) {
				const classIdsList = [row.classid, ...row.comb_classes];

				updatedChosenClassIds = updatedChosenClassIds.map((classid) => {
					if (classIdsList.includes(classid.classid)) {
						classid.combined = false;
					}
					return classid;
				});

				updatedChosenClasses = updatedChosenClasses.filter((r) => r.classid !== row.classid);
			} else {
				updatedChosenClasses = updatedChosenClasses.filter((r) => r.classid !== row.classid);
			}
		} else {
			if (row.combined === true) {
				const classIdsList = [row.classid, ...row.comb_classes];
				const index = updatedChosenClasses.findIndex((r) => r.classid === row.classid);
				updatedChosenClassIds = updatedChosenClassIds.map((classid) => {
					if (classIdsList.includes(classid.classid)) {
						classid.combined = false;
						classid.comb_classes = [];
					}
					return classid;
				});

				if (index > -1) {
					updatedChosenClasses.splice(index, 1);
				}
			} else {
				const index = updatedChosenClasses.findIndex((r) => r.classid === row.classid);
				if (index > -1) {
					updatedChosenClasses.splice(index, 1);
				}
			}
		}
		setDeletedClassIds([...deletedClassIds, row.classid]);
		if (props.inModify) props.setModifiedClasses(true);
		setClassIds(updatedChosenClassIds);
		setChosenClasses(updatedChosenClasses);
	};

	const CustomFooter = () => {
		if (props.inModifyFirst) {
			return (
				<GridFooterContainer
					sx={{
						display: "flex",
						justifyContent: "space-between" /* , gap: "10px", marginLeft: "10px"  */,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							gap: "10px",
							marginLeft: "10px",
						}}
					>
						<Button
							variant="outlined"
							sx={
								{
									/* position: "absolute", left: "5px" */
								}
							}
							onClick={handleAddClass}
						>
							Add New Classes
						</Button>
						<Button
							variant="contained"
							sx={
								{
									/* marginRight: "10px", */
								}
							}
							onClick={confirmChanges}
						>
							{changesLoading ? (
								<Typography>
									{" "}
									Please wait...
									<CircularProgress size={20} />{" "}
								</Typography>
							) : (
								`Confirm Changes`
							)}
						</Button>
						<Button
							variant="contained"
							color="error"
							sx={
								{
									/* marginRight: "10px", */
								}
							}
							onClick={cancelChanges}
						>
							Cancel
						</Button>
					</Box>
					<Box
						sx={{
							outline: chosenClasses.length > 100 ? "1px solid red" : "none",
							borderRadius: chosenClasses.length > 100 ? "5px" : "0px",
							mt: "-1px",
						}}
					>
						<GridFooter
							sx={{
								display: "flex",
							}}
						/>
					</Box>
				</GridFooterContainer>
			);
		} else {
			return (
				<GridFooterContainer sx={{ display: "flex", justifyContent: "flex-start" }}>
					<Tooltip title="Click and hold to delete all classes." placement="top-end">
						<span>
							<Button
								variant="outlined"
								color="error"
								size="small"
								disabled={chosenClasses.length === 0 ? true : false}
								sx={{
									position: "absolute",
									left: "10px",
									bottom: "10px",
								}}
								onMouseDown={(event) => handleMouseDown(event)}
								onMouseUp={handleMouseUp}
							>
								Delete All Classes
							</Button>
						</span>
					</Tooltip>
					<GridFooter sx={{ position: "absolute", right: "10px" }} />
				</GridFooterContainer>
			);
		}
	};

	const [blueChanges, setBlueChanges] = useState({});

	const renderSurveyStart = (params) => {
		return (
			<RequisitionCalendar
				value={dayjs(params.row.survey_start)}
				width="120px"
				inTable={true}
				onChange={(date) => {
					const updatedRows = [...chosenClasses];
					const index = updatedRows.findIndex((r) => r.id === params.row.id);
					updatedRows[index].survey_start = date;
					setChosenClasses(updatedRows);
					setBlueChanges((prevState) => ({
						...prevState,
						[index]: {
							...(prevState[index] || {}), // Use an empty object if prevState[params.row.classid] is undefined
							survey_start: dayjs(date).format("YYYY-MM-DD"),
							classid: params.row.classid,
						},
					}));
					if (props.inModify) props.setModifiedClasses(true);
				}}
			/>
		);
	};

	const renderSurveyEnd = (params) => {
		return (
			<RequisitionCalendar
				value={dayjs(params.row.survey_end)}
				width="120px"
				inTable={true}
				onChange={(date) => {
					const updatedRows = [...chosenClasses];
					const index = updatedRows.findIndex((r) => r.id === params.row.id);
					updatedRows[index].survey_end = date;
					setChosenClasses(updatedRows);
					setBlueChanges((prevState) => ({
						...prevState,
						[index]: {
							...(prevState[index] || {}), // Use an empty object if prevState[params.row.classid] is undefined
							survey_end: dayjs(date).format("YYYY-MM-DD"),
							classid: params.row.classid,
						},
					}));
					if (props.inModify) props.setModifiedClasses(true);
				}}
			/>
		);
	};

	const columns = [
		{
			field: "clsnum",
			headerName: "#",
			width: 30,
			editable: false,
			hide: props.inModifyFirst ? false : true,
			renderCell: (params) => {
				return params.row.clsnum ? <p>{params.row.clsnum}</p> : <strong>Waiting</strong>;
			},
		},
		{
			field: "classid",
			headerName: "Class ID",
			width: 70,
		},
		{
			field: "ctitle",
			headerName: "Class Title",
			width: 170,
			flex: 0.7,
		},
		{
			field: "csize",
			headerName: "Size",
			width: 40,
			editable: access.includes("ADMIN") || ccid === "nutlo" ? true : false,
			valueSetter: (params) => {
				const updatedRows = [...chosenClasses];
				const row = updatedRows.find((row) => row.classid === params.row.classid);
				row.csize = params.value;
				setChosenClasses(updatedRows);
				return {
					...params.row,
					csize: params.value,
				};
			},
		},
		{
			field: "name",
			headerName: "Instructor Name",
			width: 130,
		},
		{
			field: "rank",
			headerName: "Role",
			width: 50,
		},
		{
			field: "c_edate",
			headerName: "End Date",
			width: 80,
			disableClickEventBubbling: true,
			renderCell: (params) => {
				const { row } = params;
				const date = dayjs(row.c_edate);
				const formattedDate = dayjs(date).format("DD/MM/YY");
				if (formattedDate === "Invalid Date") {
					return "";
				}

				return formattedDate;
			},
		},
		{
			field: "examdate",
			headerName: "Exam Date",
			width: 80,
			disableClickEventBubbling: true,
			renderCell: (params) => {
				const { row } = params;
				const date = dayjs(row.examdate);
				const formattedDate = dayjs(date).format("DD/MM/YY");
				if (formattedDate === "Invalid Date") {
					return "";
				}
				return formattedDate;
			},
		},
		{
			field: "comb_classes",
			headerName: "Combined",
			width: 80,
			flex: 0.3,
			disableClickEventBubbling: true,
			editable: access.includes("ADMIN") ? true : false,
			valueSetter: (params) => {
				const updatedRows = [...chosenClasses];
				const index = updatedRows.findIndex((r) => r.id === params.row.id);
				updatedRows[index].comb_classes = [];
				updatedRows[index].combined = false;
				setChosenClasses(updatedRows);
				return {
					...params.row,
					comb_classes: params.value,
				};
			},
			headerAlign: "center",
		},
		{
			field: "survey_start",
			headerName: "Survey Start",
			type: "date",
			width: 130,
			headerAlign: "center",
			renderCell: renderSurveyStart,
		},
		{
			field: "survey_end",
			headerName: "Survey End",
			type: "date",
			width: 130,
			headerAlign: "center",
			renderCell: renderSurveyEnd,
		},
		{
			field: "delete",
			headerName: " ",
			width: 30,
			align: "center",

			renderCell: renderDeleteButton,
		},
	];

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				height: "100%",
				width: "100%",
			}}
		>
			<DataGrid
				sx={{
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "none !important",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
						width: "0.4em",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
						background: "#f1f1f1",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
						backgroundColor: "#888",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
						background: "#555",
					},
					border: props.greenBorder && chosenClasses.length > 0 ? "1px solid #275d38" : "grey.300",
					borderTop:
						props.greenBorder && chosenClasses.length > 0 ? "1px solid #E0E0E0" : "grey.300",
					borderRadius: props.greenBorder && "1px 1px 4px 4px",
				}}
				disableRowSelectionOnClick={true}
				components={{ Footer: CustomFooter }}
				rows={chosenClasses}
				density="compact"
				columns={columns}
				isRowSelectable={() => false}
				initialState={{
					sorting: {
						sortModel: [{ field: "ctitle", sort: "asc" }],
					},
					pagination: {
						paginationModel: { page: 0, pageSize: 5 },
					},
				}}
				pageSizeOptions={[5, 10]}
				getRowId={(row) => row.id}
			/>
		</Box>
	);
}
